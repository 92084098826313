<template>
    <div class="root_info_user">
        <div class="container ner_detail_vd">
            <div class="video_show_detail" v-for="(video, index) in listVideo" :key="index">
                <video width="100%" height="695" autoplay controls>
                    <source :src="'https://f8game.pro/assets/uploads/videos/' + video.video_path" type="video/mp4">
                </video>
                <div class="wp_info_video">
                    <div class="flex_video">
                        <div class="img_idol_video">
                            <img :src="link_web + hinhanh_idol" alt="" v-if="hinhanh_idol != null">
                            <img src="../assets/images/gx.png" alt="" v-else>
                        </div>
                        <div class="nametitle_idol_video">
                            <h3 class="nameIdol_video">{{ fullname }}</h3>
                            <p class="timePost">Ngày đăng: {{ formatDate(video.date_created) }}</p>
                            <p class="titlevideo">{{ video.title }}</p>
                        </div>
                    </div>
                    <div class="content_info_video">
                        {{ video.description }}
                    </div>
                </div>
            </div>
        </div>
        <div class="root_video_live" v-if="listOtherVideo.length > 0">
            <h2 class="title_category title_category0"><font-awesome-icon icon="fa-solid fa-video" /> <span>Video</span></h2>
            <div class="row">
                <div class="col-md-2 col-sm-2 col-xs-3 wp-item-live" v-for="(item, index) in datas" :key="index">
                    <div class="item_live">
                        <router-link v-bind:to="{ name: 'VideoDetail_nm', params: { ididol: idIdol, id: item.id } }">
                            <div class="img_live">
                                <img :src="'https://f8game.pro/assets/uploads/thumbnail/' + item.thumbnail_path" alt="">
                                <div class="hover_icon">
                                    <font-awesome-icon icon="fa-solid fa-caret-right" />
                                </div>
                            </div>
                            <div class="text_item_live text_item_live1">
                                <div class="img_live_small">
                                    <img :src="link_web + hinhanh_idol" alt="" v-if="hinhanh_idol != null">
                                    <img src="../assets/images/gx.png" alt="" v-else>
                                </div>
                                <div class="text_live_small text_live_smalls">
                                    <div class="lf_info_detail infotop">
                                        <h3 class="none_mb_h3">{{ fullname }}</h3>
                                        <!-- <p v-if="item.title.length < 12">{{ item.title }}</p>
                                        <p v-else>{{ item.title.slice(0,12) }}...</p> -->
                                    </div>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
            <paginate @update:modelValue="clickCallback" :totalCount="totalCount" :limit="perPage" v-model="page"></paginate>
        </div>
    </div>
</template>

<script>
// import $ from 'jquery'
export default {
    name: 'VideoDetail_nm',
    data () {
        return {
            id: parseInt(this.$route.params.id),
            idIdol: parseInt(this.$route.params.ididol),
            listVideo: [],
            fullname: '',
            live: '',
            hinh_anh: '',
            page: 1,
            perPage: 15,
            totalCount: 0,
            datas: [],
            lenght_vd: '',
            hinhanh_idol: '',
            listOtherVideo: []
        }
    },
    components: {
    },
    created () {
        this.getVideoView(this.id)
        this.getListInfo()
    },
    methods: {
        getListInfo () {
            this.axios.get(this.api_infidol + '?id=' + this.idIdol).then((response) => {
                this.fullname = response.data.data.user.fullname
                this.live = response.data.data.user.live
                this.hinhanh_idol = response.data.data.user.hinh_anh

                
                let username = response.data.data.user.username

                this.axios.get(this.api_listvideoidol).then(res => {
                    this.listOtherVideo = res.data.items.filter(d => d.useridol == username && d.id != this.id)
                    this.totalCount = this.listOtherVideo.length
                    this.paginatedData()
                })
            }).catch(e => {
                if (e.response.status === 401) {
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 11000
                    })
                    setTimeout(function() {
                        this.axios.post(this.api_logout, {
                        }).then(res => {
                            if (res.data.success === true) {
                                localStorage.clear();
                            }
                        })
                    }.bind(this), 5000);
                    setTimeout(function() {
                        window.location.href = '/'
                    }, 13000);
                }
            })
        },
        getVideoView (id) {
            this.axios.get(this.api_listvideoidol, {
                headers: {
                    Authorization: 'Bearer ' + window.localStorage.getItem('token')
                }
            }).then((response) => {
                this.listVideo = response.data.items.filter(d => d.id == id)
            })
        },
        paginatedData () {
            this.datas = this.listOtherVideo.slice((this.page - 1) * this.perPage, this.page * this.perPage)
        },
        clickCallback (pageNum) {
            this.page = pageNum;
            this.paginatedData();
        },
        formatDate (date) {
            var datePart = date.match(/\d+/g),
            year = datePart[2].substring(0),
            month = datePart[1], 
            day = datePart[0];
            return year + '-' + month + '-' + day;
        },
    }
}
</script>
<style>
</style>
