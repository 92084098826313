<template>
    <div class="root_info_user">
        <div class="container ner_detail_vd">
            <div class="video_show_detail" v-for="(video, index) in detailvd" :key="index">
                <iframe width="100%" height="695" class="videodetail" :src="link_web + video.video" title="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
            </div>
        </div>
        <div class="root_video_live" v-if="lenght_vd > 0">
            <h2 class="title_category title_category0"><font-awesome-icon icon="fa-solid fa-video" /> <span>PHÁT LẠI</span></h2>
            <div class="row">
                <div class="col-md-2 col-sm-2 col-xs-3 wp-item-live" v-for="(item, index) in datas" :key="index">
                    <div class="item_live">
                        <router-link v-bind:to="{ name: 'VideoDetailIdol_nm', params: { ididol: ididol, id: item.id } }">
                            <div class="img_live">
                                <img :src="link_web + item.img_video" alt="">
                                <div class="hover_icon">
                                    <font-awesome-icon icon="fa-solid fa-caret-right" />
                                </div>
                            </div>
                            <div class="text_item_live text_item_live1">
                                <div class="img_live_small">
                                    <img :src="link_web + hinhanh_idol" alt="" v-if="hinhanh_idol != null">
                                    <img src="../assets/images/gx.png" alt="" v-else>
                                </div>
                                <div class="text_live_small">
                                    <p v-if="item.title.length < 15">{{ item.title }}</p>
                                    <p v-else>{{ item.title.slice(0,15) }}...</p>
                                    <!-- <p>{{ fullname }}</p> -->
                                    <!-- <div class="type_live">
                                        <span class="sp-01" v-if="item.type != ''">{{ item.type }}</span>
                                        <p>
                                            <font-awesome-icon icon="fa-solid fa-users" />
                                            <span class="sp-02">{{ item.view }}</span>
                                        </p>
                                    </div> -->
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
            <paginate @update:modelValue="clickCallback" :totalCount="totalCount" :limit="perPage" v-model="page"></paginate>
        </div>
        <div class="root_video_live">
            <h2 class="title_category"><img src="../assets/images/images/4.png" alt=""></h2>
            <div class="wraper_game_nohu">
                <div class="row row_5_mb">
                    <div class="col-md-2 col-sm-2 col-xs-3 wp-item-live" v-for="(item, indexs) in loadMore_hotidol" :key="indexs">
                        <div class="item_live">
                            <router-link v-bind:to="{ name: 'InfoIdol_nm', params: { id: item.id } }">
                                <div class="img_live img_lives" v-if="item.sex == 0">
                                    <img :src="link_web + item.background" alt="" v-if="item.background != null">
                                    <img src="../assets/images/lv.png" alt="" v-else>
                                </div>
                                <div class="img_live img_lives" v-else>
                                    <img :src="link_web + item.background" alt="" v-if="item.background != null">
                                    <img src="../assets/images/oto.png" alt="" v-else>
                                </div>
                                <div class="text_item_live">
                                    <div class="img_live_small">
                                        <img :src="link_web + item.hinh_anh" alt=""  v-if="item.hinh_anh != null">
                                        <img src="../assets/images/user.png" alt="" v-else>
                                    </div>
                                    <div class="text_live_small">
                                        <h3 style="text-align: left;">{{ item.fullname }}</h3>
                                        <div class="type_live">
                                            <p>
                                                <font-awesome-icon icon="fa-solid fa-users" />
                                                <span class="sp-02">{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(item.point) }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
                <p class="loadsmore_hotidol" v-if="length_hot < hot_idol.length"><span @click="loadMore">Xem thêm</span></p>
            </div>
        </div>
    </div>
</template>

<script>
// import $ from 'jquery'
export default {
    name: 'VideoDetailIdol_nm',
    data () {
        return {
            id: parseInt(this.$route.params.id),
            ididol: parseInt(this.$route.params.ididol),
            listLive: [],
            detailvd: [],
            fullname: '',
            live: '',
            hinh_anh: '',
            page: 1,
            perPage: 15,
            totalCount: 0,
            datas: [],
            lenght_vd: '',
            hinhanh_idol: '',
            hot_idol: [],
            length_hot: 10
        }
    },
    components: {
    },
    created () {
        this.getInfo(this.id)
        this.getLisIdolAll()
    },
    computed: {
        loadMore_hotidol() {
            return this.hot_idol.slice(0, this.length_hot);
        },
    },
    methods: {
        getInfo (id) {
            this.axios.get(this.api_infidol + '?id=' + this.ididol).then((response) => {
                this.detailvd = response.data.data.list_data.filter(d => d.id === id)
                this.hinhanh_idol = response.data.data.user.hinh_anh

                this.listLive = response.data.data.list_data.filter(d => d.id !== id)
                this.lenght_vd = this.listLive.length
                this.totalCount = this.listLive.length
                this.paginatedData()
            }).catch(e => {
                if (e.response.status === 401) {
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 11000
                    })
                    setTimeout(function() {
                        this.axios.post(this.api_logout, {
                        }).then(res => {
                            if (res.data.success === true) {
                                localStorage.clear();
                            }
                        })
                    }.bind(this), 5000);
                    setTimeout(function() {
                        window.location.href = '/'
                    }, 13000);
                }
            })
        },
        paginatedData () {
            this.datas = this.listLive.slice((this.page - 1) * this.perPage, this.page * this.perPage)
        },
        clickCallback (pageNum) {
            this.page = pageNum;
            this.paginatedData();
        },
        getLisIdolAll () {
            this.axios.get(this.api_listall).then((response) => {
                this.hot_idol = this.shuffle(response.data.data.list_user)
            })
        },
        loadMore() {
            if (this.length_hot > this.hot_idol.length) return;
            this.length_hot = this.length_hot + 5;
        },
        shuffle(arra1) {
            var ctr = arra1.length, temp, index;
            while (ctr > 0) {
                index = Math.floor(Math.random() * ctr);
                ctr--;
                temp = arra1[ctr];
                arra1[ctr] = arra1[index];
                arra1[index] = temp;
            }
            return arra1;
        }
    }
}
</script>
<style>
</style>
