<template>
    <div class="root_info_user">
        <div class="container ner_detail_vd">
            <div class="wp_slider_video_detail">
                <Carousel :wrap-around="false" :breakpoints="breakpoints" @slide-start="handleSlideStart">
                    <Slide v-for="(video, index) in listVideo" :key="index+1">
                        <div class="video_show_detail">
                            <video width="100%" height="695" id="video1" autoplay controls class="videoDetail_watch" controlsList="nodownload">
                                <source :src="'https://f8game.pro/assets/uploads/videos/' + video.video_path" type="video/mp4">
                            </video>
                            <div class="wp_info_video">
                                <div class="flex_video">
                                    <div class="img_idol_video">
                                        <img :src="'https://f8game.pro/assets/uploads/thumbnail/' + video.thumbnail_path" alt="" style="object-fit: cover;">
                                    </div>
                                    <div class="nametitle_idol_video">
                                        <h3 class="nameIdol_video">{{ video.fullname }}</h3>
                                        <p class="timePost">Ngày đăng: {{ formatDate(video.date_created) }}</p>
                                        <p class="titlevideo">{{ video.title }}</p>
                                    </div>
                                </div>
                                <div class="content_info_video">
                                    {{ video.description }}
                                </div>
                            </div>
                        </div>
                    </Slide>
                    <Slide v-for="(video, index) in listOtherVideo.slice(0, 20)" v-bind:key="index+1">
                        <div class="video_show_detail">
                            <video width="100%" height="695" :id="'video'+parseInt(index+2)" controls class="videoDetail_watch" controlsList="nodownload">
                                <source :src="'https://f8game.pro/assets/uploads/videos/' + video.video_path" type="video/mp4">
                            </video>
                            <div class="wp_info_video">
                                <div class="flex_video">
                                    <div class="img_idol_video">
                                        <img :src="'https://f8game.pro/assets/uploads/thumbnail/' + video.thumbnail_path" alt="" style="object-fit: cover;">
                                    </div>
                                    <div class="nametitle_idol_video">
                                        <h3 class="nameIdol_video">{{ video.fullname }}</h3>
                                        <p class="timePost">Ngày đăng: {{ formatDate(video.date_created) }}</p>
                                        <p class="titlevideo">{{ video.title }}</p>
                                    </div>
                                </div>
                                <div class="content_info_video">
                                    {{ video.description }}
                                </div>
                            </div>
                        </div>
                    </Slide>
                    <template #addons>
                        <Navigation />
                    </template>
                </Carousel>
            </div>
        </div>
        <div class="root_video_live" v-if="listOtherVideo.length > 0">
            <h2 class="title_category title_category0"><font-awesome-icon icon="fa-solid fa-video" /> <span>Video</span></h2>
            <div class="row">
                <div class="col-md-2 col-sm-2 col-xs-3 wp-item-live" v-for="(item, index) in datas" :key="index">
                    <div class="item_live">
                        <router-link v-bind:to="{ name: 'VideoPlay_nm', params: { id: item.id } }">
                            <div class="img_live img_live_video">
                                <!-- <img :src="'https://f8game.pro/assets/uploads/thumbnail/' + item.thumbnail_path" alt=""> -->
                                <video ref="video" :src="'https://f8game.pro/assets/uploads/videos/' + item.video_path" :data_id="item.id" height="120" :class="'videoplay_hover videoplay_hover'+item.id" muted @mouseover="playHover(index)" @mouseleave="pauseHover(index)"></video>
                                <!-- <div class="hover_icon">
                                    <font-awesome-icon icon="fa-solid fa-caret-right" />
                                </div> -->
                            </div>
                            <div class="text_item_live text_item_live1">
                                <div class="img_live_small">
                                    <img :src="'https://f8game.pro/assets/uploads/thumbnail/' + item.thumbnail_path" alt="">
                                </div>
                                <div class="text_live_small videoLeft">
                                    <h3>{{ item.fullname }}</h3>
                                    <!-- <p v-if="item.title.length < 20">{{ item.title }}</p>
                                    <p v-else>{{ item.title.slice(0,20) }}...</p> -->
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
            <paginate @update:modelValue="clickCallback" :totalCount="totalCount" :limit="perPage" v-model="page"></paginate>
        </div>
    </div>
</template>

<script>
// import $ from 'jquery'
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'
export default {
    name: 'VideoPlay_nm',
    setup() {
        return {
            breakpoints: {
                0: {
					itemsToShow: 1,
					snapAlign: "start"
				},
			},
        }
    },
    data () {
        return {
            id: parseInt(this.$route.params.id),
            listVideo: [],
            page: 1,
            perPage: 15,
            totalCount: 0,
            datas: [],
            listOtherVideo: [],
            videosState: {},
            videosStates: {}
        }
    },
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    created () {
        this.getVideoView()
    },
    methods: {
        getVideoView () {
            this.axios.get(this.api_listvideoidol).then((response) => {
                this.listVideo = response.data.items.filter(d => d.id == this.id)
                this.listOtherVideo = response.data.items.filter(d => d.id != this.id)
                this.totalCount = this.listOtherVideo.length
                this.paginatedData()
            })
        },
        paginatedData () {
            this.datas = this.listOtherVideo.slice((this.page - 1) * this.perPage, this.page * this.perPage)
        },
        clickCallback (pageNum) {
            this.page = pageNum;
            this.paginatedData();
        },
        formatDate (date) {
            var datePart = date.match(/\d+/g),
            year = datePart[2].substring(0),
            month = datePart[1], 
            day = datePart[0];
            return year + '-' + month + '-' + day;
        },
        playHover (idx) {
            // console.log(e.target.getAttribute('data_id'));
            if (!this.videosState[idx]) {
                this.videosState[idx] = {
                    play: false
                }
            }
            const isPlaying = this.videosState[idx].play;
            const video = this.$refs.video[idx];
            if (!isPlaying) {
                video.play();
            } else {
                video.pause();
            }
            this.videosState[idx].play = !isPlaying
        },
        pauseHover (idx) {
            if (!this.videosState[idx]) {
                this.videosState[idx] = {
                    play: false
                }
            }
            const isPlaying = this.videosState[idx].play;
            const video = this.$refs.video[idx];
            video.pause();
            this.videosState[idx].play = !isPlaying
        },
        play (id) {
            let vid = document.getElementById(id)
            vid.play(); 
        },
        pause (id) {
            let vid = document.getElementById(id)
            vid.pause();
        },
        handleSlideStart(data) {
            let vdt = data.slidingToIndex
            let vdg = data.slidingToIndex + 1
            let vds = data.slidingToIndex + 2
            this.play('video'+vdg)
            this.pause('video'+vdt)
            this.pause('video'+vds)
        },
    }
}
</script>
<style>
</style>
