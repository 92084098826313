<template>
    <div class="root_social">
        <div class="container">
            <div class="wp_social">

                <div class="createdPost">
                    <div class="contentCreated">
                        <div class="imgus">
                            <img :src="link_web + hinh_anh" alt="">
                        </div>
                        <p @click="created_mdPost">Tạo bài viết</p>
                    </div>
                </div>

                <div class="wp_item_social" v-for="(item, index) in listPost" :key="index">
                    <div class="item_social">
                        <div class="info_user_social">
                            <div class="img_user_social">
                                <img :src="link_web + item.hinh_anh" alt="">
                            </div>
                            <div class="name_user_social">
                                <p class="name_us_sca">{{ item.fullname }}</p>
                                <p class="time_social">10 phút</p>
                            </div>
                        </div>
                        <div class="content_social" :class="{ wp_videoPost: item.video != '' }">
                            <div class="text_social">
                                <div class="status_social">
                                    <p>{{ item.content }}</p>
                                </div>
                                <div :class="'wp_img_videoPost divimgPost' + item.images.split(';').length">
                                    <Fancybox
                                        :options="{
                                            Carousel: {
                                            infinite: false,
                                            },
                                        }"
                                    >
                                        <a class="img_video_social videowwpPost" v-if="item.video != ''">
                                            <video controls>
                                                <source :src="link_web + item.video" type="video/mp4">
                                            </video>
                                        </a>
                                        <a class="img_video_social" data-fancybox="gallery" :href="link_web + items" v-for="(items, indexs) in item.images.split(';')" :key="indexs">
                                            <img :src="link_web + items" alt="">
                                        </a>
                                    </Fancybox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <!-- modal created post -->
            <div id="modal-example" class="modal-login">
                <div class="overlay" v-if="showModalPost" @click="showModalPost = false"></div>
                <div class="modal" v-if="showModalPost">
                    <div class="wp-modal-login">
                        <div class="header-modal">
                            <button class="close-modal_login" @click="showModalPost = false"><img src="../assets/images/close_md.png" alt=""></button>
                        </div>
                        <div class="content-modal_tab">
                            <div>
                                <div class="logo_modal_login">
                                    <div class="logo_mdal">
                                        <div class="img_md">
                                            <img :src="link_web + logoWb" alt="">
                                        </div>
                                    </div>
                                </div>
                                <form action="" method="post" id="frm_createdPost" @submit="createdPost">
                                    <div class="input_modal">
                                        <input type="text" v-model="title" placeholder="Tiêu đề">
                                    </div>
                                    <div class="input_modal">
                                        <input type="text" v-model="content" placeholder="Nội dung">
                                    </div>
                                    <div class="input_modal">
                                        <input type="file" @change="changeImg" accept="image/*" name="chose_image" class="chose_image inp_hidden" id="chose_image" multiple />
                                        <label class="chose_image chosefile" for="chose_image">
                                            <span>Chọn ảnh</span>
                                        </label>
                                    </div>
                                    <div class="input_modal">
                                        <input type="file" @change="changeVideo" accept="video/mp4,video/x-m4v,video/*" name="chose_videoPost" class="chose_videoPost inp_hidden" id="chose_videoPost" />
                                        <label class="chose_videoPost chosefile" for="chose_videoPost">
                                            <span>Chọn video</span>
                                        </label>
                                    </div>
                                    <div class="btn_modal_login btn_md_createdpost">
                                        <div class="btn_lg_md">
                                            <button type="submit">Gửi</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end created post -->
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
import Fancybox from './Fancybox.vue';
$( document ).ready(function() {
    // $(document).on('change', '.chose_image', function() {
    //     $('label[for="chose_image"] span').text($(this).val());
    // });
    // $(document).on('change', '.chose_videoPost', function() {
    //     $('label[for="chose_videoPost"] span').text($(this).val());
    // });
});
export default {
    name: 'Social_nm',
    data () {
        return {
            listPost: [],
            hinh_anh: window.localStorage.getItem('img'),
            fullname: window.localStorage.getItem('fullname'),
            showModalPost: false,
            title: '',
            content: '',
            logoWb: ''
        }
    },
    components: {
        Fancybox
    },
    created () {
        this.getListPost()
        this.getInfoLink()
    },
    methods: {
        getInfoLink () {
            this.axios.get(this.api_previewLink + '?domain=' + this.url_param).then((response) => {
                this.logoWb = response.data.data.list_data.logo
            })
        },
        getListPost () {
            this.axios.get(this.api_listPost,
            {
                headers: {
                    Authorization: 'Bearer ' + window.localStorage.getItem('token')
                }
            }
            ).then((response) => {
                this.listPost = response.data.data.list_posts
            })
        },
        created_mdPost () {
            this.showModalPost = true
        },
        changeImg () {
            let arrImg = document.getElementById('chose_image').files
            let file_img = document.getElementById('chose_image').files[0]
            if(arrImg.length==1){
                $('label[for="chose_image"] span').text(file_img.name);
            }else{
               $('label[for="chose_image"] span').text(arrImg.length + ' file được chọn'); 
            }
        },
        changeVideo () {
            let fileVideo = document.getElementById('chose_videoPost').files[0]
            $('label[for="chose_videoPost"] span').text(fileVideo.name);
        },
        createdPost (e) {
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            let imgLength = document.getElementById('chose_image').files.length
            let file_img = document.getElementById('chose_image').files[0]
            let arrImg = document.getElementById('chose_image').files
            let file_video = document.getElementById('chose_videoPost').files[0]

            const form = new FormData();
            form.append('title', this.title);
            form.append('content', this.content);
            if(imgLength==1){
                form.append('images[]', file_img);
            }else{
                arrImg.forEach(item => {
                    form.append('images[]', item);
                });
            }
            form.append('video', file_video);
            this.axios.post(this.api_CreatedPost, 
                form,
                {
                    headers: {
                        Authorization: 'Bearer ' + window.localStorage.getItem('token')
                    }
                }
            ).then(res => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
                this.showModalPost = false
                this.title = this.content = ''
                $('#chose_image').val('')
                $('#chose_videoPost').val('')
                this.getListPost()
                // window.location.reload()
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                if (e.response.status === 404) {
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                }
            })
        }
    }
}
</script>
<style>
</style>
