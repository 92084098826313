<template>
    <div class="root_info_user">
        <div class="info_wrapper">
            <div class="wrapper_info">
                <img src="../assets/images/bn.webp" alt="">
            </div>
            <div class="text_info">
                <div class="img_info">
                    <div class="mobile_change">
                        <img :src="link_web + hinh_anh" alt="" v-if="hinh_anh !== null">
                        <img src="../assets/images/user.png" alt="" v-else>
                        <!-- <br />
                        <button type="button" v-if="type==1 && token == null" @click="showModal = true">+ Theo dõi</button>
                        <button type="button" v-if="type==1 && checkFollow == 0" @click="followIdol">+ Theo dõi</button>
                        <button type="button" v-else @click="unfollowIdol">Bỏ theo dõi</button> -->
                        <!-- <font-awesome-icon icon="fa-solid fa-pen" /> -->
                        <font-awesome-icon icon="fa-solid fa-camera"  v-if="type==1" @click="showModal_img = true"/>
                        <font-awesome-icon icon="fa-solid fa-camera" class="svg_viewuser" v-else @click="showModal_img = true"/>
                    </div>
                </div>
                <div class="info_user_right">
                    <div class="level_infous">
                        <p class="p-id">Level: <img :src="link_web + level_icon" alt="" class="imglevel"> {{ level }}</p>
                        <p class="level_next">Bạn cần thêm {{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(level_exp_next) }} xu để tăng cấp lên <b>{{ level_name_next }}</b></p>
                    </div>
                    <div class="p-name" v-if="type==1">
                        <p class="edit_name">
                            <span class="p_name_1">
                                {{ fullname }}
                            </span>
                            <font-awesome-icon icon="fa-solid fa-pen-to-square" @click="showModal_edit = true" />
                        </p>
                        <span v-if="live > 0" class="p_name_2"><font-awesome-icon icon="fa-solid fa-headset" style="color: green;" /> Đang stream</span>
                        <span v-else class="p_name_2"><font-awesome-icon icon="fa-solid fa-power-off" /> offline</span>
                    </div>
                    <p class="p-name pname_edit" v-else>
                        <span class="p_name_1">{{ fullname }}</span>
                        <font-awesome-icon icon="fa-solid fa-pen-to-square" @click="showModal_edit = true" />
                    </p>
                    <div class="follow_fan wd100" v-if="type==1">
                        <div class="follows">
                            <p class="p-tdf">Theo dõi</p>
                            <p class="p-sl">{{ follow }}</p>
                        </div>
                        <div class="fans">
                            <p class="p-tdf">Xu được tặng</p>
                            <p class="p-sl">{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(amount_sticker) }}</p>
                        </div>
                        <div class="amount_info">
                            <p class="p-tdf">Xu của bạn</p>
                            <p class="p-sl">{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(amount) }}</p>
                        </div>
                    </div>
                    <div class="follow_fan" v-else>
                        <div class="follow follownotPoint">
                            <p class="p-tdf">Số xu</p>
                            <p class="p-sl">{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(amount) }}</p>
                        </div>
                        <!-- <div class="fan">
                            <p class="p-tdf">Điểm</p>
                            <p class="p-sl">{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(point) }}</p>
                        </div> -->
                    </div>
                    <!-- <div class="btn_uploads" v-if="type==1">
                        <button type="button" @click="showModal = true"><font-awesome-icon icon="fa-solid fa-upload" /> Tải video lên</button>
                    </div> -->
                    <!-- modal edit name -->
                    <div id="modal-example" class="modal-login">
                        <div class="overlay" v-if="showModal_edit" @click="showModal = false"></div>
                        <div class="modal" v-if="showModal_edit">
                            <div class="wp-modal-login wp-modal-change_preview">
                                <div class="header-modal">
                                    <ul class="list_menu_modal">
                                        <!-- <li class="img_modal"><img :src="link_web + logoWb"></li> -->
                                        <li v-bind:class="{active: tabSelected == 'changename'}" v-on:click="changeTab('changename')">Đổi tên</li>
                                        <li v-if="typeus==1" v-bind:class="{active: tabSelected == 'preview'}" v-on:click="changeTab('preview')">Mô tả</li>
                                    </ul>
                                    <button class="close-modal_login" @click="showModal_edit = false"><img src="../assets/images/close_md.png" alt=""></button>
                                </div>
                                <div class="content-modal_tab">
                                    <div  v-if="tabSelected === 'changename'">
                                        <form action="" method="post" class="frm_editname" @submit="changeName">
                                            <input type="text" required v-model="fullname_edit" class="fullname_input" placeholder="Họ tên" />
                                            <button type="submit">Gửi</button>
                                        </form>
                                    </div>
                                    <div  v-if="tabSelected === 'preview'">
                                        <form action="" method="post" class="frm_editname" @submit="previewIdol">
                                            <p class="title_pin_mes">Mô tả</p>
                                            <ckeditor :editor="editor" class="content_pre" :config="editorConfig" v-model="preview"></ckeditor>
                                            <div class="input_link">
                                                <label>Link game: </label>
                                                <input type="text" v-model="link_game">
                                            </div>
                                            <div class="input_link">
                                                <label>Link zalo: </label>
                                                <input type="text" v-model="zalo">
                                            </div>
                                            <div class="input_link">
                                                <label>Link facebook: </label>
                                                <input type="text" v-model="facebook">
                                            </div>
                                            <div class="input_link">
                                                <label>Link telegram: </label>
                                                <input type="text" v-model="telegram">
                                            </div>
                                            <div class="messpin_type" v-if="offline == 1">
                                                <p class="title_pin_mes" style="margin-top: 10px;">Tin nhắn ghim</p>
                                                <ckeditor :editor="editor" :config="editorConfig" v-model="message"></ckeditor>
                                            </div>
                                            <button type="submit">Gửi</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end modal edit name -->
                    <!-- modal uploads video -->
                    <div id="modal-example" class="modal-login">
                        <div class="overlay" v-if="showModal" @click="showModal = false"></div>
                        <div class="modal" v-if="showModal">
                            <div class="wp-modal-login">
                                <div class="header-modal">
                                    <ul class="list_menu_modal">
                                        <!-- <li class="img_modal"><img :src="link_web + logoWb"></li> -->
                                        <li class="active">Tải video</li>
                                    </ul>
                                    <button class="close-modal_login" @click="showModal = false"><img src="../assets/images/close_md.png" alt=""></button>
                                </div>
                                <div class="content-modal_tab">
                                    <div>
                                        <form action="" method="post" id="frm_uploads" @submit="uploadsVideo">
                                            <input type="text" required v-model="title" class="title_up_video" placeholder="Tiêu đề" />
                                            <div class="wp_input_up">
                                                <input type="file" required name="file_img_video" id="file_img_video" />
                                                <label class="file_img_video" for="file_img_video">
                                                    <font-awesome-icon icon="fa-solid fa-image" />
                                                    <span>Chọn ảnh</span>
                                                </label>
                                            </div>
                                            <div class="">
                                                <input type="file" required name="file_video_up" id="file_video_up" />
                                                <label class="file_video_up" for="file_video_up">
                                                    <font-awesome-icon icon="fa-solid fa-video" />
                                                    <span>Chọn video</span>
                                                </label>
                                            </div>
                                            <button type="submit">Tải lên</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end modal uploads video -->
                    <!-- modal change avarta -->
                    <div id="modal-example" class="modal-login">
                        <div class="overlay" v-if="showModal_img" @click="showModal_img = false"></div>
                        <div class="modal" v-if="showModal_img">
                            <div class="wp-modal-login">
                                <div class="header-modal">
                                    <ul class="list_menu_modal">
                                        <!-- <li class="img_modal"><img :src="link_web + logoWb"></li> -->
                                        <li class="active">Đổi avarta</li>
                                    </ul>
                                    <button class="close-modal_login" @click="showModal_img = false"><img src="../assets/images/close_md.png" alt=""></button>
                                </div>
                                <div class="content-modal_tab">
                                    <div>
                                        <form action="" method="post" id="frm_uploads_img" @submit="ChangeAvarta">
                                            <input type="file" @change="changetextimg" required name="change_avarta" id="change_avarta" accept="image/*" />
                                            <label class="change_avarta" for="change_avarta">
                                                <!-- <font-awesome-icon icon="fa-solid fa-image" /> -->
                                                <span>Chọn ảnh</span>
                                            </label>
                                            <button type="submit">Tải lên</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end modal change avarta -->
                </div>
            </div>
            <div class="wrapper_intro" style="margin-bottom: 30px;" v-if="typeus == 1">
                <div class="previewidol_live">
                    <div class="cat_chanel">
                        <ul>
                            <li><img :src="link_web + logoWb" alt=""></li>
                            <li :class="{active: tabSelecteds === 'about'}" v-on:click="changeTabs('about')">Giới thiệu</li>
                            <!-- <li :class="{active: tabSelecteds === 'chanel'}" v-on:click="changeTabs('chanel')">Kênh idol</li> -->
                            <li :class="{active: tabSelecteds === 'video'}" v-on:click="changeTabs('video')">Video</li>
                            <li :class="{active: tabSelecteds === 'follow'}" v-on:click="changeTabs('follow')">Theo dõi</li>
                            <li :class="{active: tabSelecteds === 'historycash'}" v-on:click="changeTabs('historycash')">Nạp tiền</li>
                            <li :class="{active: tabSelecteds === 'historycashout'}" v-on:click="changeTabs('historycashout')">Rút tiền</li>
                            <li :class="{active: tabSelecteds === 'giftSticker'}" v-on:click="changeTabs('giftSticker')">Quà tặng</li>
                        </ul>
                    </div>
                    <div class="content_tab_chanel">
                        <div v-if="tabSelecteds === 'about'">
                            <div class="intro_about">
                                <div v-if="intro != null" v-html="intro"></div>
                                <p v-if="lk_link_game != null">Link tải game: <a :href="lk_link_game" target="_blank">{{ lk_link_game }}</a></p>
                                <p v-if="lk_zalo != null">Link zalo: <a :href="lk_zalo" target="_blank">{{ lk_zalo }}</a></p>
                                <p v-if="lk_facebook != null">Link facebook: <a :href="lk_facebook" target="_blank">{{ lk_facebook }}</a></p>
                                <p v-if="lk_telegram != null">Link telegram: <a :href="lk_telegram" target="_blank">{{ lk_telegram }}</a></p>

                                <p v-if="serverkey != null" @click="copyToClipBoard(serverkey)" title="click để copy" class="copiesText copiesTexts"><span>Server key:</span> {{ serverkey }}</p><br />
                                <p v-if="streamkey != null" @click="copyToClipBoard(streamkey)" title="click để copy" class="copiesText"><span>Stream key:</span> {{ streamkey }}</p>
                            </div>
                        </div>
                        <div v-if="tabSelecteds === 'chanel'">
                            <div class="wrapper_content_cn">
                                <button type="button" class="created_chanel" @click="clickCreated">+ Tạo kênh</button>
                                <div class="list_chanel">
                                    <h4>Danh sách kênh</h4>
                                    <table class="table_chanel">
                                        <thead>
                                            <tr>
                                                <th width="7%">STT</th>
                                                <th width="50%">Tên kênh</th>
                                                <th width="23%">Ngày tạo</th>
                                                <th width="20%">chức năng</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="listChanel.length > 0">
                                            <tr v-for="(item, index) in listChanel" :key="index">
                                                <td>{{ index + 1 }}</td>
                                                <td>
                                                    <router-link v-bind:to="{ name: 'MessageGroup_nm', params: { ididol: idUser, idcheck: item.is_chat, id: item.id } }">{{ item.name }}</router-link>
                                                </td>
                                                <td>{{ formatDate(item.ngay_cap_nhat) }}</td>
                                                <td>
                                                    <button type="button" :data_id="item.id" :data_check="item.is_chat" :data_val="item.name" class="btn1" @click="clickEdit($event)"></button>
                                                    <button type="button" :data_id="item.id" class="btn2" @click="deleteChanel($event)"></button>
                                                    <button type="button" :data_id="item.id" class="btn3" @click="memberChanel($event)"></button>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr>
                                                <td colspan="4">Chưa có kênh</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div v-if="tabSelecteds === 'video'" class="wp_video_root">
                            <div class="row mar_top_row" v-if="totalCount > 0">
                                <div class="col-md-2 col-sm-2 col-xs-3 wp-item-live item_video_idoldetail" v-for="(item, index) in datas" :key="index">
                                    <div class="item_live">
                                        <router-link v-bind:to="{ name: 'VideoDetail_nm', params: { ididol: numberus, id: item.id } }">
                                            <div class="img_live">
                                                <!-- <img :src="'https://f8game.pro/assets/uploads/thumbnail/' + item.thumbnail_path" alt=""> -->
                                                <video ref="video" :src="'https://f8game.pro/assets/uploads/videos/' + item.video_path" :data_id="item.id" height="120" :class="'videoplay_hover videoplay_hover'+item.id" muted @mouseover="playHover(index)" @mouseleave="pauseHover(index)"></video>
                                                <!-- <div class="hover_icon">
                                                    <font-awesome-icon icon="fa-solid fa-caret-right" />
                                                </div> -->
                                            </div>
                                            <div class="text_item_live text_item_live1">
                                                <div class="img_live_small">
                                                    <img :src="link_web + hinh_anh" alt="" v-if="hinh_anh != null">
                                                    <img src="../assets/images/gx.png" alt="" v-else>
                                                </div>
                                                <div class="text_live_small text_live_smalls">
                                                    <div class="lf_info_detail infotop">
                                                        <h3 class="none_mb_h3">{{ fullname }}</h3>
                                                        <p v-if="item.title.length < 12">{{ item.title }}</p>
                                                        <p v-else>{{ item.title.slice(0,12) }}...</p>
                                                    </div>
                                                    <!-- <div class="delete_video">
                                                        <img src="../assets/images/delete.png" @click="DeleteVideo" alt="" title="Xóa video" :data_id="item.id">
                                                    </div> -->
                                                </div>
                                            </div>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                            <paginate @update:modelValue="clickCallback" :totalCount="totalCount" :limit="perPage" v-model="page"></paginate>
                        </div>
                        <div v-if="tabSelecteds === 'follow'">
                            <div class="wrapper_content_cn">
                                <div class="list_chanel tablecash_history">
                                    <table class="table_chanel">
                                        <thead>
                                            <tr>
                                                <th width="10%">STT</th>
                                                <th width="60%">Họ tên</th>
                                                <th width="30%">Hình ảnh</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="listhistorycash.length > 0">
                                            <tr v-for="(item, index) in dataFl" :key="index">
                                                <td>{{ index + 1 }}</td>
                                                <td>{{ item.fullname }}</td>
                                                <td class="image_history">
                                                    <Fancybox>
                                                        <a data-fancybox="gallery" :href="link_web + item.hinh_anh">
                                                            <img :src="link_web + item.hinh_anh" alt="">
                                                        </a>
                                                    </Fancybox>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr>
                                                <td colspan="4">Dữ liệu rỗng</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <paginate @update:modelValue="clickCallbacks" :totalCount="totalCounts" :limit="perPages" v-model="pages"></paginate>
                                </div>
                            </div>
                        </div>
                        <div v-if="tabSelecteds === 'historycash'">
                            <div class="wrapper_content_cn">
                                <div class="list_chanel tablecash_history">
                                    <table class="table_chanel">
                                        <thead>
                                            <tr>
                                                <th width="7%">STT</th>
                                                <th width="15%">Số tiền</th>
                                                <th width="20%">Hình ảnh</th>
                                                <th width="30%">Ghi chú</th>
                                                <th width="18%">Ngày tạo</th>
                                                <th width="10%">Tình trạng</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="listhistorycash.length > 0">
                                            <tr v-for="(item, index) in datass" :key="index">
                                                <td>{{ index + 1 }}</td>
                                                <td>{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(item.amount) }}</td>
                                                <td class="image_history">
                                                    <Fancybox>
                                                        <a data-fancybox="gallery" :href="link_web + item.hinh_anh">
                                                            <img :src="link_web + item.hinh_anh" alt="">
                                                        </a>
                                                    </Fancybox>
                                                </td>
                                                <td class="note_cash">{{ item.note }}</td>
                                                <td>{{ formatDates(item.ngay_tao) }}</td>
                                                <td>
                                                    <span :class="'statusbtn' + item.status" v-if="item.status == 0">Từ chối</span>
                                                    <span :class="'statusbtn' + item.status" v-else-if="item.status == 1">Đã duyệt</span>
                                                    <span :class="'statusbtn' + item.status" v-else>Chưa duyệt</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr>
                                                <td colspan="4">Dữ liệu rỗng</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <paginate @update:modelValue="clickCallbacks" :totalCount="totalCounts" :limit="perPages" v-model="pages"></paginate>
                                </div>
                            </div>
                        </div>
                        <div v-if="tabSelecteds === 'historycashout'">
                            <div class="wrapper_content_cn">
                                <div class="list_chanel tablecash_history">
                                    <table class="table_chanel">
                                        <thead>
                                            <tr>
                                                <th width="10%">STT</th>
                                                <th width="30%">Số xu rút</th>
                                                <th width="30%">Số tiền nhận</th>
                                                <th width="30%">Ngày tạo</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="listhistorycash.length > 0">
                                            <tr v-for="(item, index) in datasss" :key="index">
                                                <td>{{ index + 1 }}</td>
                                                <td>{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(item.amount) }}</td>
                                                <td>{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(item.amount_change) }}</td>
                                                <td>{{ formatDates(item.ngay_tao) }}</td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr>
                                                <td colspan="4">Dữ liệu rỗng</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <paginate @update:modelValue="clickCallbackss" :totalCount="totalCountss" :limit="perPagess" v-model="pagess"></paginate>
                                </div>
                            </div>
                        </div>
                        <div v-if="tabSelecteds === 'giftSticker'">
                            <div class="wrapper_content_cn">
                                <div class="list_chanel tablecash_history">
                                    <table class="table_chanel">
                                        <thead>
                                            <tr>
                                                <th width="10%">STT</th>
                                                <th width="20%">Người tặng</th>
                                                <th width="30%">Hình ảnh</th>
                                                <th width="10%">Số xu</th>
                                                <th width="30%">Ngày tạo</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="listhistorycash.length > 0">
                                            <tr v-for="(item, index) in dataG" :key="index">
                                                <td>{{ index + 1 }}</td>
                                                <td>{{ item.fullname }}</td>
                                                <td class="imgGiftsticker"><img :src="link_web + item.sticker" alt=""></td>
                                                <td>{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(item.amount) }}</td>
                                                <td>{{ formatDates(item.ngay_tao) }}</td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr>
                                                <td colspan="4">Dữ liệu rỗng</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <paginate @update:modelValue="clickCallbackss" :totalCount="totalCountss" :limit="perPagess" v-model="pagess"></paginate>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="wrapper_intro" style="margin-bottom: 30px;" v-else>
                <div class="previewidol_live">
                    <div class="cat_chanel">
                        <ul>
                            <li><img :src="link_web + logoWb" alt=""></li>
                            <!-- <li :class="{active: tabSelecteds === 'about'}" v-on:click="changeTabs('about')">Kênh đã tham gia</li> -->
                            <li :class="{active: tabSelecteds === 'about'}" v-on:click="changeTabs('about')">Nạp tiền</li>
                        </ul>
                    </div>
                    <div class="content_tab_chanel">
                        <div v-if="tabSelecteds === 'historycash'">
                            <div class="wrapper_content_cn">
                                <div class="list_chanel">
                                    <h4>Danh sách kênh đã tham gia</h4>
                                    <table class="table_chanel">
                                        <thead>
                                            <tr>
                                                <th width="7%">STT</th>
                                                <th width="50%">Tên kênh</th>
                                                <th width="23%">Ngày tạo</th>
                                                <th width="20%">chức năng</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="listChanel.length > 0">
                                            <tr v-for="(item, index) in listChanel" :key="index">
                                                <td>{{ index + 1 }}</td>
                                                <td>
                                                    <router-link v-bind:to="{ name: 'MessageGroup_nm', params: { ididol: item.user_id, idcheck: item.is_chat, id: item.id } }">{{ item.name }}</router-link>
                                                </td>
                                                <td>{{ formatDate(item.ngay_cap_nhat) }}</td>
                                                <td><router-link class="btn5" v-bind:to="{ name: 'MessageGroup_nm', params: { ididol: item.user_id, idcheck: item.is_chat, id: item.id } }">Chi tiết</router-link></td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr>
                                                <td colspan="4">Chưa có kênh</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div v-if="tabSelecteds === 'about'">
                            <div class="wrapper_content_cn">
                                <div class="list_chanel tablecash_history">
                                    <table class="table_chanel">
                                        <thead>
                                            <tr>
                                                <th width="7%">STT</th>
                                                <th width="15%">Số tiền</th>
                                                <th width="20%">Hình ảnh</th>
                                                <th width="30%">Ghi chú</th>
                                                <th width="18%">Ngày tạo</th>
                                                <th width="10%">Tình trạng</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="listhistorycash.length > 0">
                                            <tr v-for="(item, index) in datass" :key="index">
                                                <td>{{ index + 1 }}</td>
                                                <td>{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(item.amount) }}</td>
                                                <td class="image_history">
                                                    <Fancybox>
                                                        <a data-fancybox="gallery" :href="link_web + item.hinh_anh">
                                                            <img :src="link_web + item.hinh_anh" alt="">
                                                        </a>
                                                    </Fancybox>
                                                </td>
                                                <td class="note_cash">{{ item.note }}</td>
                                                <td>{{ formatDates(item.ngay_tao) }}</td>
                                                <td>
                                                    <span :class="'statusbtn' + item.status" v-if="item.status == 0">Từ chối</span>
                                                    <span :class="'statusbtn' + item.status" v-else-if="item.status == 1">Đã duyệt</span>
                                                    <span :class="'statusbtn' + item.status" v-else>Chưa duyệt</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr>
                                                <td colspan="6">Dữ liệu rỗng</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <paginate @update:modelValue="clickCallbacks" :totalCount="totalCounts" :limit="perPages" v-model="pages"></paginate>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- modal created group -->
            <div id="modal-example" class="modal-login">
                <div class="overlay" v-if="showModal_created" @click="showModal_created = false"></div>
                <div class="modal" v-if="showModal_created">
                    <div class="wp-modal-login">
                        <div class="header-modal">
                            <ul class="list_menu_modal">
                                <li class="img_modal"><img :src="link_web + logoWb"></li>
                                <li class="active" v-if="check==1">Tạo kênh</li>
                                <li class="active" v-else-if="check == 2">Sửa kênh</li>
                                <li class="active" v-else>Danh sách thành viên</li>
                            </ul>
                            <button class="close-modal_login" @click="showModal_created = false"><img src="../assets/images/close_md.png" alt=""></button>
                        </div>
                        <div class="content-modal_tab">
                            <div v-if="check == 3" class="listMember_group">
                                <ul v-if="listMember.length > 0">
                                    <li v-for="(items, indexs) in listMember" :key="indexs"><span>{{ items.fullname }}</span> <img src="../assets/images/delete.png" alt="" :data_idus="items.id" @click="deleteMember($event)"></li>
                                </ul>
                                <ul v-else>
                                    <li style="border:none;text-align:left;">Chưa có thành viên</li>
                                </ul>
                            </div>
                            <div v-else>
                                <form action="" method="post" id="frm_createdGroup" @submit="createdGroup">
                                    <input type="text" placeholder="Tên kênh" v-model="nameGroup" class="input_css" />
                                    <div class="checkbox-wrapper-17" v-if="checkedb == 0">
                                        <span>Chat:</span>
                                        <input type="checkbox" id="switch-17" />
                                        <label for="switch-17"></label>
                                    </div>
                                    <div class="checkbox-wrapper-17" v-else>
                                        <span>Chat:</span>
                                        <input type="checkbox" id="switch-17" checked />
                                        <label for="switch-17"></label>
                                    </div>
                                    <button type="submit" class="btn_css">Gửi</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end modal created group -->
        <!-- <div class="root_video_live" v-if="lenght_vd > 0" style="display: none;">
            <h2 class="title_category title_category0"><font-awesome-icon icon="fa-solid fa-video" /> <span>PHÁT LẠI</span></h2>
            <div class="row">
                <div class="col-md-2 col-sm-2 col-xs-3 wp-item-live" v-for="(item, index) in datas" :key="index">
                    <div class="item_live">
                            <router-link v-bind:to="{ name: 'VideoDetailIdol_nm', params: { ididol: numberus, id: item.id } }">
                            <div class="img_live">
                                <img :src="link_web + item.img_video" alt="">
                                <div class="hover_icon">
                                    <font-awesome-icon icon="fa-solid fa-caret-right" />
                                </div>
                            </div>
                            <div class="text_item_live text_item_live1">
                                <div class="img_live_small">
                                    <img :src="link_web + hinh_anh" alt="" v-if="hinh_anh != null">
                                    <img src="../assets/images/gx.png" alt="" v-else>
                                </div>
                                <div class="text_live_small text_live_smalls">
                                    <div class="lf_info_detail infotop">
                                        <h3 class="none_mb_h3">{{ fullname }}</h3>
                                        <p v-if="item.title.length < 12">{{ item.title }}</p>
                                        <p v-else>{{ item.title.slice(0,12) }}...</p>
                                    </div>
                                    <div class="delete_video">
                                        <img src="../assets/images/delete.png" @click="DeleteVideo" alt="" title="Xóa video" :data_id="item.id">
                                    </div>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
            <paginate @update:modelValue="clickCallback" :totalCount="totalCount" :limit="perPage" v-model="page"></paginate>
        </div> -->
    </div>
</template>

<script>
import {reactive} from "vue"
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import $ from 'jquery'
import Fancybox from './Fancybox.vue';
import Swal from 'sweetalert2'
export default {
    name: 'Info_nm',
    setup() {
        const data = reactive({
            description: '',
        })
        return {
            data,
            editor: ClassicEditor
        }
    },
    data () {
        return {
            id: '',
            fullname: '',
            live: '',
            hinh_anh: '',
            listLive: [],
            video: '',
            showModal: false,
            showModal_img: false,
            hinhanh: '',
            title: '',
            page: 1,
            perPage: 12,
            totalCount: 0,
            datas: [],
            type: '',
            point_rank: '',
            lenght_vd: '',
            point: '',
            idUser: parseInt(this.$route.params.id),
            token: window.localStorage.getItem('token'),
            typeus: window.localStorage.getItem('typeus'),
            numberus: window.localStorage.getItem('numberus'),
            usIdol: window.localStorage.getItem('us'),
            checkFollow: 0,
            follow: 0,
            showModal_edit: false,
            fullname_edit: '',
            tabSelected: 'changename',
            preview: '',
            editorConfig: {
                toolbar: [ 'bold', 'italic', 'link', 'numberedList', 'table'],
            },
            intro: '',
            link_game: '',
            zalo: '',
            facebook: '',
            telegram: '',
            lk_link_game: '',
            lk_zalo: '',
            lk_facebook: '',
            lk_telegram: '',
            tabSelecteds: 'about',
            listChanel: [],
            showModal_created: false,
            nameGroup: '',
            check: 1,
            idChanelClick: '',
            listMember: [],
            listVideo: [],
            videosState: {},
            message: '',
            offline: '',
            amount: '',
            listhistorycash: [],
            pages: 1,
            perPages: 15,
            totalCounts: 0,
            datass: [],
            checkedb: 0,
            serverkey: '',
            streamkey: '',
            amount_sticker: '',

            pagess: 1,
            perPagess: 15,
            totalCountss: 0,
            datasss: [],
            listCashout: [],

            listFollow: [],
            pageFl: 1,
            perPageFl: 15,
            totalCountFl: 0,
            dataFl: [],

            listGifSticker: [],
            pageG: 1,
            perPageG: 15,
            totalCountG: 0,
            dataG: [],
            logoWb: '',
            level: '',
            level_icon: '',
            level_exp_next: '',
            level_name_next: ''
        }
    },
    components: {
        Fancybox
    },
    created () {
        this.getListInfo()
        this.getListChanel()
        this.GetDataListVideo()
        this.Getlisthistorycash()
        this.getListCashout()
        this.getListFollow()
        this.getListgiftsticker()
        this.getInfoLink()
    },
    methods: {
        getInfoLink () {
            this.axios.get(this.api_previewLink + '?domain=' + this.url_param).then((response) => {
                this.logoWb = response.data.data.list_data.logo
            })
        },
        changeTab (tab) {
            this.tabSelected = tab
        },
        getListInfo () {
            this.axios.get(this.api_infouser, {
                headers: {
                    Authorization: 'Bearer ' + window.localStorage.getItem('token')
                }
            }).then((response) => {
                this.id = response.data.data.user.id
                this.fullname = response.data.data.user.fullname
                this.live = response.data.data.user.live
                this.hinh_anh = response.data.data.user.hinh_anh
                this.intro = response.data.data.user.intro
                this.type = response.data.data.user.type
                this.point_rank = response.data.data.user.point_rank
                this.point = response.data.data.user.point
                this.follow = response.data.data.user.follow_total
                this.lk_link_game = response.data.data.user.link_game
                this.lk_zalo = response.data.data.user.zalo
                this.lk_facebook = response.data.data.user.facebook
                this.lk_telegram = response.data.data.user.telegram
                this.serverkey = response.data.data.user.server_key_1
                this.streamkey = response.data.data.user.stream_key_1
                
                this.preview = response.data.data.user.intro
                this.link_game = response.data.data.user.link_game
                this.zalo = response.data.data.user.zalo
                this.facebook = response.data.data.user.facebook
                this.telegram = response.data.data.user.telegram
                this.fullname_edit = response.data.data.user.fullname
                this.message = response.data.data.user.pin_message
                this.offline = response.data.data.user.offline
                this.amount = response.data.data.user.amount
                this.amount_sticker = response.data.data.user.amount_sticker
                this.level = response.data.data.user.level
                this.level_icon = response.data.data.user.level_icon
                this.level_exp_next = response.data.data.user.level_exp_next
                this.level_name_next = response.data.data.user.level_name_next
                console.log(response.data.data.user);
            }).catch(e => {
                if (e.response.status === 401) {
                    setTimeout(function() {
                        this.axios.post(this.api_logout, {
                        }).then(res => {
                            if (res.data.success === true) {
                                localStorage.clear();
                            }
                        })
                    }.bind(this), 5000);
                    setTimeout(function() {
                        window.location.href = '/'
                    }, 13000);
                }
            })
        },
        ChangeAvarta (e) {
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            let file_img = document.getElementById('change_avarta').files[0]
            const form = new FormData();
            form.append('hinh_anh', file_img);
            this.axios.post(this.api_change_avarta, 
                form,
                {
                    headers: {
                        Authorization: 'Bearer ' + window.localStorage.getItem('token')
                    }
                }
            ).then(res => {
                // console.log('res', res);
                this.showModal_img = false
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
                window.location.reload()
            }).catch(e => {
                // console.log('e', e);
                $('#loading_show').css('display', 'none')
                if(e.response.status === 404){
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                }
            })
        },
        uploadsVideo (e) { 
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            this.title = $('.title_up_video').val();
            let file_img = document.getElementById('file_img_video').files[0]
            let file_video = document.getElementById('file_video_up').files[0]
            const form = new FormData();
            form.append('title', this.title);
            form.append('img_video', file_img);
            form.append('video', file_video);
            this.axios.post(this.api_uploads_video, 
                form,
                {
                    headers: {
                        Authorization: 'Bearer ' + window.localStorage.getItem('token')
                    }
                }
            ).then(res => {
                // console.log('res', res);
                this.showModal = false
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
                window.location.reload()
            }).catch(e => {
                // console.log('e', e);
                $('#loading_show').css('display', 'none')
                if(e.response.status === 404){
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                }
            })
        },
        DeleteVideo (e) {
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            var idVideo = Number(e.target.getAttribute('data_id'))
            this.axios.post(this.api_delete_video, 
                {
                    id: idVideo
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + window.localStorage.getItem('token')
                    }
                }
            ).then(res => {
                // console.log('res', res);
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
                window.location.reload()
            }).catch(e => {
                // console.log('e', e);
                $('#loading_show').css('display', 'none')
                if(e.response.status === 404){
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                }
            })
        },
        followIdol (e) {
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            this.axios.post(this.api_follow, 
                {
                    id: this.idUser
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + window.localStorage.getItem('token')
                    }
                }
            ).then(res => {
                // console.log('res', res);
                $('#loading_show').css('display', 'none')
                this.checkFollow = 1
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
            }).catch(e => {
                // console.log('e', e);
                $('#loading_show').css('display', 'none')
                this.checkFollow = 0
                if(e.response.status === 404){
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                }
            })
        },
        unfollowIdol (e) {
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            this.axios.post(this.api_unfollow, 
                {
                    id: this.idUser
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + window.localStorage.getItem('token')
                    }
                }
            ).then(res => {
                // console.log('res', res);
                $('#loading_show').css('display', 'none')
                this.checkFollow = 0
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
            }).catch(e => {
                // console.log('e', e);
                $('#loading_show').css('display', 'none')
                this.checkFollow = 1
                if(e.response.status === 404){
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                }
            })
        },
        changeName (e) {
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            var namechange = $('.fullname_input').val()
            this.axios.post(this.api_changename, 
                {
                    name: this.fullname_edit
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + window.localStorage.getItem('token')
                    }
                }
            ).then(res => {
                // console.log('res', res);
                $('#loading_show').css('display', 'none')
                this.showModal_edit = false
                this.fullname_edit = ''
                $('.p_name_1').text(namechange)
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
            }).catch(e => {
                // console.log('e', e);
                $('#loading_show').css('display', 'none')
                // this.checkFollow = 1
                if(e.response.status === 404){
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                }
            })
        },
        previewIdol (e) {
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            this.axios.post(this.api_preview, 
                {
                    intro: this.preview,
                    link_game: this.link_game,
                    zalo: this.zalo,
                    facebook: this.facebook,
                    telegram: this.telegram,
                    pin_message: this.message,
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + window.localStorage.getItem('token')
                    }
                }
            ).then(res => {
                // console.log('res', res);
                $('#loading_show').css('display', 'none')
                this.showModal_edit = false
                this.preview = ''
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
                window.location.reload()
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                if(e.response.status === 404){
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                }
            })
        },
        changeTabs (tab) {
            this.tabSelecteds = tab
        },
        getListChanel () {
            this.axios.get(this.api_listGroup, {
                headers: {
                    Authorization: 'Bearer ' + window.localStorage.getItem('token')
                }
            }).then((response) => {
                this.listChanel = response.data.data
            })
        },
        formatDate (date) {
            var splitdate = date.split(' ')
            var datePart = splitdate[0].match(/\d+/g),
            year = datePart[2].substring(0),
            month = datePart[1], 
            day = datePart[0];
            var gpg = splitdate[1].split(':');
            return  gpg[0] + ':' + gpg[1] + ' ' + year + '-' + month + '-' + day;
        },
        formatDates (date) {
            var splitdate = date.split(' ')
            var datePart = splitdate[0].match(/\d+/g),
            year = datePart[2].substring(0),
            month = datePart[1], 
            day = datePart[0];
            var gpg = splitdate[1].split(':');
            return  year + '-' + month + '-' + day + ' ' + gpg[0] + ':' + gpg[1];
        },
        deleteChanel (event) {
            $('.loading_show').show().fadeIn(10)
            var idChanel = event.target.getAttribute('data_id')
            this.axios.post(this.api_deleteGroup, 
                {
                    id: idChanel
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + window.localStorage.getItem('token')
                    }
                }
            ).then(res => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
                this.getListChanel()
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                if(e.response.status === 404){
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                }
            })
        },
        clickCreated () {
            this.showModal_created = true
            this.check = 1
        },
        clickEdit (event) {
            this.idChanelClick = event.target.getAttribute('data_id')
            this.nameGroup = event.target.getAttribute('data_val')
            this.checkedb = event.target.getAttribute('data_check')
            this.showModal_created = true
            this.check = 2
        },
        memberChanel (event) {
            this.idChanelClick = event.target.getAttribute('data_id')
            this.showModal_created = true
            this.check = 3
            this.getDataListMember()
        },
        createdGroup (e) {
            e.preventDefault()
            this.showModal_created = false
            $('.loading_show').show().fadeIn(10)
            let checkedBox = document.getElementById('switch-17');
            let is_chat = ''
            if (checkedBox.checked){
                is_chat = 1
            }else{
                is_chat = 0
            }
            if(this.check == 1){
                this.axios.post(this.api_createdGroup, 
                    {
                        name: this.nameGroup,
                        is_chat: is_chat
                    },
                    {
                        headers: {
                            Authorization: 'Bearer ' + window.localStorage.getItem('token')
                        }
                    }
                ).then(res => {
                    $('#loading_show').css('display', 'none')
                    this.nameGroup = ''
                    this.$swal({
                        position: 'top-end',
                        icon: 'success',
                        title: res.data.message,
                        showConfirmButton: false,
                        timer: 4000
                    })
                    this.getListChanel()
                }).catch(e => {
                    $('#loading_show').css('display', 'none')
                    if(e.response.status === 404){
                        this.$swal({
                            position: 'top-end',
                            icon: 'error',
                            title: e.response.data.message,
                            showConfirmButton: false,
                            timer: 3000
                        })
                    }
                })
            }else if(this.check == 2){
                this.axios.post(this.api_editGroup, 
                    {
                        id: this.idChanelClick,
                        name: this.nameGroup,
                        is_chat: is_chat
                    },
                    {
                        headers: {
                            Authorization: 'Bearer ' + window.localStorage.getItem('token')
                        }
                    }
                ).then(res => {
                    $('#loading_show').css('display', 'none')
                    this.nameGroup = ''
                    this.$swal({
                        position: 'top-end',
                        icon: 'success',
                        title: res.data.message,
                        showConfirmButton: false,
                        timer: 4000
                    })
                    this.getListChanel()
                }).catch(e => {
                    $('#loading_show').css('display', 'none')
                    if(e.response.status === 404){
                        this.$swal({
                            position: 'top-end',
                            icon: 'error',
                            title: e.response.data.message,
                            showConfirmButton: false,
                            timer: 3000
                        })
                    }
                })
            }
        },
        getDataListMember () {
            this.axios.get(this.api_listMember + '?id=' + this.idChanelClick,
                {
                    headers: {
                        Authorization: 'Bearer ' + window.localStorage.getItem('token')
                    }
                }
            ).then((response) => {
                this.listMember = response.data.data
            })
        },
        deleteMember (event) {
            event.preventDefault()
            $('.loading_show').show().fadeIn(10)
            var userID = event.target.getAttribute('data_idus')
            this.axios.post(this.api_clickExitGroupt, 
                {
                    id: this.idChanelClick,
                    user_id: userID
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + window.localStorage.getItem('token')
                    }
                }
            ).then(res => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
                this.getDataListMember()
            })
        },
        GetDataListVideo () {
            this.axios.get(this.api_listvideoUs + '?useridol=' + this.usIdol).then(res => {
                this.listVideo = res.data.items
                this.totalCount = this.listVideo.length
                this.paginatedData()
            })
        },
        paginatedData () {
            this.datas = this.listVideo.slice((this.page - 1) * this.perPage, this.page * this.perPage)
        },
        clickCallback (pageNum) {
            this.page = pageNum;
            this.paginatedData();
        },
        playHover (idx) {
            // console.log(e.target.getAttribute('data_id'));
            if (!this.videosState[idx]) {
                this.videosState[idx] = {
                    play: false
                }
            }
            const isPlaying = this.videosState[idx].play;
            const video = this.$refs.video[idx];
            if (!isPlaying) {
                video.play();
            } else {
                video.pause();
            }
            this.videosState[idx].play = !isPlaying
        },
        pauseHover (idx) {
            if (!this.videosState[idx]) {
                this.videosState[idx] = {
                    play: false
                }
            }
            const isPlaying = this.videosState[idx].play;
            const video = this.$refs.video[idx];
            video.pause();
            this.videosState[idx].play = !isPlaying
        },
        Getlisthistorycash () {
            this.axios.get(this.api_historycash, {
                headers: {
                    Authorization: 'Bearer ' + window.localStorage.getItem('token')
                }
            }).then(res => {
                this.listhistorycash = res.data.data.data
                this.totalCounts = this.listhistorycash.length
                this.paginatedDatas()
            })
        },
        paginatedDatas () {
            this.datass = this.listhistorycash.slice((this.pages - 1) * this.perPages, this.pages * this.perPages)
        },
        clickCallbacks (pageNum) {
            this.pages = pageNum;
            this.paginatedDatas();
        },
        copyToClipBoard (textToCopy) {
            navigator.clipboard.writeText(textToCopy)
            Swal.fire({
                position: 'top-end',
                showConfirmButton: false,
                title: 'Copy thành công',
                icon: 'success',
                timer: 2000
            });
        },
        getListCashout () {
            this.axios.get(this.api_listcashout, {
                headers: {
                    Authorization: 'Bearer ' + window.localStorage.getItem('token')
                }
            }).then(res => {
                this.listCashout = res.data.data.data
                this.totalCountss = this.listCashout.length
                this.paginatedDatass()
            })
        },
        paginatedDatass () {
            this.datasss = this.listCashout.slice((this.pagess - 1) * this.perPagess, this.pagess * this.perPagess)
        },
        clickCallbackss (pageNum) {
            this.pagess = pageNum;
            this.paginatedDatass();
        },
        getListFollow () {
            this.axios.get(this.api_historyFollow, {
                headers: {
                    Authorization: 'Bearer ' + window.localStorage.getItem('token')
                }
            }).then(res => {
                this.listFollow = res.data.data.data
                this.totalCountFl = this.listFollow.length
                this.paginatedDataFl()
            })
        },
        paginatedDataFl () {
            this.dataFl = this.listFollow.slice((this.pageFl - 1) * this.perPageFl, this.pageFl * this.perPageFl)
        },
        clickCallbackFl (pageNum) {
            this.pageFl = pageNum;
            this.paginatedDataFl();
        },
        changetextimg () {
            let fileVideo = document.getElementById('change_avarta').files[0]
            $('label[for="change_avarta"] span').text(fileVideo.name);
        },
        getListgiftsticker () {
            this.axios.get(this.api_giftSticker, {
                headers: {
                    Authorization: 'Bearer ' + window.localStorage.getItem('token')
                }
            }).then(res => {
                this.listGifSticker = res.data.data.data
                this.totalCountG = this.listGifSticker.length
                this.paginatedDataG()
            })
        },
        paginatedDataG () {
            this.dataG = this.listGifSticker.slice((this.pageG - 1) * this.perPageG, this.pageG * this.perPageG)
        },
        clickCallbackG (pageNum) {
            this.pageG = pageNum;
            this.paginatedDataG();
        },
    }
}
</script>
<style>

</style>
