<template>
    <div id="content" class="ner_responesize content-margin" :class="{checkHidHeader: checkHidHeader==1}">
        <div class="content-section root_live_dtidol sticky-container" sticky-container>
            <div class="row">
                <div class="col-lg-9 xs-12 iframe-live" id="live-body_sss">
                    <div :class="{desktop_mq: item.device == 1}" class="wp-live-stream" v-for="(item, index) in items" v-bind:key="index">
                        <div class="wp_ifo_detaile">
                            <div class="ifo_detaile">
                                <div class="img-detail">
                                    <router-link v-bind:to="{ name: 'InfoIdol_nm', params: { id: item.id } }">
                                        <img :src="link_web + item.hinh_anh" alt="" v-if="item.hinh_anh !== null">
                                        <img src="../assets/images/user.png" alt="" v-else>
                                    </router-link>
                                </div>
                                <div class="text_detail">
                                    <h3>{{ item.fullname }}</h3>
                                    <div>
                                        <span class="title_live_edit">{{ item.tieu_de }}</span>
                                        <span class="point_live"><img src="../assets/images/d.png" alt="">Điểm: <b>{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(item.point) }}</b></span>
                                        <span class="share_live">
                                            <img src="../assets/images/cs.png" alt=""> Chia sẻ
                                            <a v-bind:href="'https://www.facebook.com/sharer/sharer.php?u=' + link_webs + currentUrl" target="_blank" role="button" tabindex="0" class="at-icon-wrapper at-share-btn at-svc-facebook">
                                                <img src="../assets/images/cs1.png" alt="">
                                            </a>
                                            <a v-bind:href="'https://telegram.me/share/url?url=' + link_webs + currentUrl" target="_blank" role="button" tabindex="0" class="at-icon-wrapper at-share-btn at-svc-telegram">
                                                <img src="../assets/images/cs2.png" alt="">
                                            </a>
                                            <a @click="copyToClipBoard(link_webs + currentUrl)" id="copyClick" role="button" tabindex="0" class="at-icon-wrapper at-share-btn at-svc-link">
                                                <img src="../assets/images/cs3.png" alt="">
                                            </a>
                                            <button type="button" @click="showModal = true" class="registeridol" v-if="token == null">Follow</button>
                                            <button type="button" @click="followIdol" class="registeridol" v-else-if="checkFolow==0 && token != null">Follow</button>
                                            <button type="button" @click="unfollowIdol" class="registeridol" v-else>Hủy follow</button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="livemb_number" style="display: none;">
                                <img src="../assets/images/mb1.png" alt="">
                                <span><img src="../assets/images/mb2.png" alt=""><b>{{ current_view }}</b></span>
                            </div>
                        </div>
                        <div class="wp-video-api">
                            <div :class="'vietnam-live ' + fixed_scroll">
                                <div class="wp_detaile_live" v-if="country == 'Vietnam'">
                                    <div v-if="item.device == 1" class="desktop_stream_dt wrapper_video_dt">
                                        <iframe width="100%" name="iframevn" class="iframe_videof8_dt" id="iframevn" :src="item.luong" title="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                                    </div>
                                    <div v-else :class="'mobile_stream_dt ' + classMb">
                                        <iframe name="iframevn" height="533" class="iframe_videof8_dt" id="iframevn" :src="item.luong" title="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                                    </div>
                                </div>
                                <div class="wp_detaile_live" v-else>
                                    <div v-if="item.device == 1" class="desktop_stream_dt">
                                        <vue3-video-player
                                            autoplay
                                            :core="HLSCore"
                                            :src="item.link_m3u8"
                                            title=""
                                            resolution="408p"
                                            :view-core="viewCore.bind(null, 'videolivecam')"
                                            id="videolivecam"
                                        />
                                    </div>
                                    <div v-else class="mobile_stream_dt">
                                        <vue3-video-player
                                            autoplay
                                            :core="HLSCore"
                                            :src="item.link_m3u8"
                                            title=""
                                            resolution="408p"
                                            :view-core="viewCore.bind(null, 'videolivecam')"
                                            id="videolivecam"
                                        />
                                    </div>
                                </div>
                                <div :class="'marque_div marque_div' + id_live"></div>
                                <div :class="'animation_pointMember animation_pointMember' + id_live"></div>
                            </div>
                            <div class="pst_in_video">
                                <p class="number_view_detail"><font-awesome-icon icon="fa-solid fa-users" /> <span>{{ current_view }}</span></p>
                            </div>
                            <div class="list_icon_chat_live">
                                <div class="item_icon_chat_live">
                                    <carousel :breakpoints="breakpoints" :wrap-around="false">
                                        <slide v-for="(item, index) in listIcon" v-bind:key="index">
                                            <img :data_img="item.id" :src="link_web + item.url" alt="" class="icon_chat_send_live" @click="sendIconChat">
                                            <span class="point_level_live" v-if="item.amount == 0" :data_img="item.id" @click="sendIconChat">{{ item.point}} điểm</span>
                                            <span class="point_level_live" v-else :data_img="item.id" @click="sendIconChat">{{ item.amount}} xu</span>
                                        </slide>
                                        <template #addons>
                                            <navigation />
                                            <!-- <pagination /> -->
                                        </template>
                                    </carousel>
                                </div>
                            </div>
                        </div>
                        <div class="img_show_hide_chat">
                            <p class="show_chat"><img src="../assets/images/show_chat.png" alt=""></p>
                            <p class="hide_chat"><img src="../assets/images/hide_chat.png" alt=""></p>
                        </div>
                        <div class="wrapper_message_pin showmobile_pin" v-if="offline == 1">
                            <h3 class="message_pin_s" id="message_pin" v-html="pin_message"></h3>
                        </div>
                        <div class="wrapper_message_pin showmobile_pin" v-else>
                            <h3 class="message_pin_s" id="message_pin" v-html="messagePin"></h3>
                        </div>
                    </div>
                    <div class="wp_cat_live_list listmobile_none list_live_other list_live_other_des" v-if="listLiveidol.length > 0">
                        <div class="title_item_idol">
                            <h3>
                                <img src="../assets/images/gy.png" alt="">
                                <span>Được gợi ý</span>
                            </h3>
                        </div>
                        <div class="wop_slider_other">
                            <Carousel :wrap-around="false" :breakpoints="breakpointssl">
                                <Slide v-for="(item, indexs) in listLiveidol.slice(0,10)" :key="indexs">
                                    <div class="item_live item_live_97">
                                        <router-link v-bind:to="{ name: 'LiveDetail_nm', params: { id: item.id, idlive: item.id_live } }">
                                            <div class="img_live">
                                                <img :src="link_web + item.background" alt="" v-if="item.background != null">
                                                <img src="../assets/images/lv.png" alt="" v-else>
                                                <div class="hover_icon">
                                                    <font-awesome-icon icon="fa-solid fa-caret-right" />
                                                </div>
                                            </div>
                                            <div class="text_item_live">
                                                <div class="img_live_small">
                                                    <img :src="link_web + item.hinh_anh" alt="" v-if="item.hinh_anh != null">
                                                    <img src="../assets/images/gx.png" alt="" v-else>
                                                </div>
                                                <div class="text_live_small">
                                                    <h3>{{ item.fullname }}</h3>
                                                    <div class="type_live">
                                                        <p>
                                                            <img src="../assets/images/eye.svg" alt="">
                                                            <span class="sp-02">{{ item.total_view }}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <img src="../assets/images/live.png" alt="" class="img_live_green">
                                            </div>
                                        </router-link>
                                    </div>
                                </Slide>
                                <template #addons>
                                    <pagination />
                                </template>
                            </Carousel>
                        </div>
                    </div>
                </div>
                <img src="../assets/images/hide_chat.png" alt="" class="none_pin_mes">
                <div class="col-lg-3 xs-12 iframe-chat">
                    <div class="sticky" v-sticky="stickyEnabled" sticky-offset="{top: 70, bottom: 0}">
                        <div class="wp-chat-stream" v-if="nameslug == 'LiveDetail_nm'">
                            <div class="position_right">
                                <div class="chat-text" v-bind:id="this.$route.params.idlive">
                                </div>
                                <FormChat_nm />
                            </div>
                            <div class="wrapper_message_pin" v-if="offline == 1">
                                <h3 class="message_pin_s" id="message_pin" v-html="pin_message"></h3>
                            </div>
                            <div class="wrapper_message_pin" v-else>
                                <h3 class="message_pin_s" id="message_pin" v-html="messagePin"></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="wp_cat_live_list listmobile_none list_live_other list_live_other_mob" v-if="listLiveidol.length > 0">
            <div class="title_item_idol">
                <h3>
                    <img src="../assets/images/gy.png" alt="">
                    <span>Được gợi ý</span>
                </h3>
            </div>
            <div class="wop_slider_other">
                <Carousel :wrap-around="false" :breakpoints="breakpointssl">
                    <Slide v-for="(item, indexs) in listLiveidol.slice(0,10)" :key="indexs">
                        <div class="item_live item_live_97">
                            <router-link v-bind:to="{ name: 'LiveDetail_nm', params: { id: item.id, idlive: item.id_live } }">
                                <div class="img_live">
                                    <img :src="link_web + item.background" alt="" v-if="item.background != null">
                                    <img src="../assets/images/lv.png" alt="" v-else>
                                    <div class="hover_icon">
                                        <font-awesome-icon icon="fa-solid fa-caret-right" />
                                    </div>
                                </div>
                                <div class="text_item_live">
                                    <div class="img_live_small">
                                        <img :src="link_web + item.hinh_anh" alt="" v-if="item.hinh_anh != null">
                                        <img src="../assets/images/gx.png" alt="" v-else>
                                    </div>
                                    <div class="text_live_small">
                                        <h3>{{ item.fullname }}</h3>
                                        <div class="type_live">
                                            <p>
                                                <img src="../assets/images/eye.svg" alt="">
                                                <span class="sp-02">{{ item.total_view }}</span>
                                            </p>
                                        </div>
                                    </div>
                                    <img src="../assets/images/live.png" alt="" class="img_live_green">
                                </div>
                            </router-link>
                        </div>
                    </Slide>
                    <template #addons>
                        <pagination />
                    </template>
                </Carousel>
            </div>
        </div>
        <!-- modal uploads Message -->
        <div id="modal-example" class="modal-login">
            <div class="overlay" v-if="showModal" @click="showModal = false"></div>
            <div class="modal" v-if="showModal">
                <div class="wp-modal-login">
                    <div class="header-modal">
                        <ul class="list_menu_modal">
                            <!-- <li class="img_modal"><img src="../assets/images/logo.png"></li> -->
                            <li class="active">Cập nhật tin nhắn</li>
                        </ul>
                        <button class="close-modal_login" @click="showModal = false"><img src="../assets/images/close_md.png" alt=""></button>
                    </div>
                    <div class="content-modal_tab">
                        <div>
                            <form action="" method="post" id="frm_uploads_mess" @submit="updateMess">
                                <!-- <input type="text" v-model="messPin" placeholder="Nội dung" /> -->
                                <p class="title_pin_mes">Tin nhắn ghim</p>
                                <ckeditor :editor="editor" :config="editorConfig" v-model="messPin"></ckeditor>
                                <button type="submit">Gửi</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end modal uploads Message -->
        <!-- modal login -->
        <div id="modal-example" class="modal-login">
            <div class="overlay" v-if="showModal" @click="showModal = false"></div>
            <div class="modal" v-if="showModal">
                <div class="wp-modal-login">
                    <div class="header-modal">
                        <button class="close-modal_login" @click="showModal = false"><img src="../assets/images/close_md.png" alt=""></button>
                    </div>
                    <div class="content-modal_tab">
                        <div>
                            <div class="logo_modal_login">
                                <div class="logo_mdal">
                                    <div class="img_md">
                                        <img :src="link_web + logoWb" alt="">
                                    </div>
                                </div>
                            </div>
                            <Login_f8 @showMd="showModalrg" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end modal login -->
        <!-- modal register -->
        <div id="modal-example" class="modal-login">
            <div class="overlay" v-if="showModal_dk" @click="showModal_dk = false"></div>
            <div class="modal" v-if="showModal_dk">
                <div class="wp-modal-register">
                    <div class="header-modal">
                        <button class="close-modal_login" @click="showModal_dk = false"><img src="../assets/images/close_md.png" alt=""></button>
                    </div>
                    <div class="content-modal_tab">
                        <div>
                            <div class="logo_modal_login">
                                <div class="logo_mdal">
                                    <div class="img_md">
                                        <img :src="link_web + logoWb" alt="">
                                    </div>
                                </div>
                            </div>
                            <div class="form_dangky">
                                <Register_f8 @showMd="showModallg"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end modal register -->
        <!-- modal chat -->
        <div id="modal-example" class="modal-login">
            <div class="overlay" v-if="showModalsp" @click="showModalsp = false"></div>
            <div class="modal" v-if="showModalsp">
                <div class="wp-modal-login wp-modal-support">
                    <div class="header-modal">
                        <ul class="list_menu_modal">
                            <!-- <li class="img_modal"><img src="../assets/images/logo.png"></li> -->
                            <li class="active">Trợ giúp</li>
                        </ul>
                        <button class="close-modal_login" @click="showModalsp = false"><img src="../assets/images/close_md.png" alt=""></button>
                    </div>
                    <div class="content-modal_tab">
                        <div>
                            <form action="" method="post" id="frm_chat" @submit="sendSupport">
                                <div>
                                    <Select2 v-model="topic" :options="listtopic" placeholder="Chọn tiêu đề">
                                    </Select2>
                                </div>
                                <!-- <select v-model="topic">
                                    <option value="" selected>Chọn tiêu đề</option>
                                    <option :value="item.id" v-for="(item, index) in listtopic" :key="index">{{ item.title }}</option>
                                </select> -->
                                <input type="text" v-model="titlesp" placeholder="Tiêu đề">
                                <input type="text" v-model="content" placeholder="Nội dung">
                                <button type="submit">Gửi</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end modal chat -->
        <!-- modal give gift -->
        <div id="modal-example" class="modal-login">
            <div class="overlay" v-if="showModal_gift" @click="showModal_gift = false"></div>
            <div class="modal" v-if="showModal_gift">
                <div class="wp-modal-login">
                    <div class="header-modal">
                        <ul class="list_menu_modal">
                            <!-- <li class="img_modal"><img src="../assets/images/logo.png"></li> -->
                            <li class="active">Nhận quà</li>
                        </ul>
                        <button class="close-modal_login" @click="showModal_gift = false"><img src="../assets/images/close_md.png" alt=""></button>
                    </div>
                    <div class="content-modal_tab">
                        <div>
                            <form action="" method="post" @submit="sendInfoGift" id="frm_givegift">
                                <input type="text" v-if="token==null" v-model="fullname" autocomplete="off" placeholder="Họ tên">
                                <input type="text" v-model="phone" autocomplete="off" placeholder="Số điện thoại">
                                <button type="submit">Gửi</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end give gift -->
        <!-- <a :href="linkgame" class="gameicon_mobile" target="_blank"><img src="../assets/images/game.gif" alt=""></a> -->
    </div>
</template>

<script>
import Login_f8 from '@/components/Login'
import Register_f8 from '@/components/Register'
import HLSCore from '@cloudgeek/playcore-hls'
import moment from 'moment'
import SocketIO from 'socket.io-client'
import Swal from 'sweetalert2'

import $ from 'jquery'
import FormChat_nm from '@/components/FormChat'

import {reactive} from "vue"
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

import Select2 from 'vue3-select2-component';

// import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

$( document ).ready(function() {
    $(document).on('click', '.hide_chat', function() {
        $('.wp-chat-stream').addClass('hide_chat_click');
        $(this).hide()
        $('.show_chat').show()
    });
    $(document).on('click', '.show_chat', function() {
        $('.wp-chat-stream').removeClass('hide_chat_click');
        $(this).hide()
        $('.hide_chat').show()
    });
    $(document).on('click', '.none_pin_mes', function() {
        $('.wrapper_message_pin.showmobile_pin').addClass('noneClick');
        $('.wp_ifo_detaile').hide()
        $(this).hide()
    });
    $(document).on('click', '.hidechat_desktop', function() {
        $(this).addClass('active');
        $('.rowAbs .iframe-chat').addClass('none_dk')
    });
    $(document).on('click', '.hidechat_desktop.active', function() {
        $(this).removeClass('active');
        $('.rowAbs .iframe-chat').removeClass('none_dk')
    });

    var socket = SocketIO('https://adm.liveshow.top',{ transports: ['websocket', 'polling', 'flashsocket'] });
    socket.on('chat:pin_message',function(result){
        let str = '';
        str = `${result.data.message}`;
        if(str === 'null'){
            $(`#message_pin`).html('');
        }else{
            $(`#message_pin`).html(str);
        }
    });
});
export default {
    name: 'LiveDetail_nm',
    setup() {
        const data = reactive({
            description: '',
        })
        return {
            data,
            editor: ClassicEditor,
            breakpointssl: {
                0: {
					itemsToShow: 1.5,
					snapAlign: "start"
				},
				575: {
					itemsToShow: 2,
					snapAlign: "start"
				},
				1024: {
					itemsToShow: 3,
					snapAlign: "start"
				},
				1280: {
					itemsToShow: 4,
					snapAlign: "start"
				},
				1440: {
					itemsToShow: 5,
					snapAlign: "start"
				},
			},
            breakpoints: {
                0: {
					itemsToShow: 4,
					snapAlign: "start"
				},
                667: {
					itemsToShow: 5,
					snapAlign: "start"
				},
				768: {
					itemsToShow: 7,
					snapAlign: "start"
				},
				1024: {
					itemsToShow: 10,
					snapAlign: "start"
				},
                1364: {
					itemsToShow: 14,
					snapAlign: "start"
				},
                1600: {
					itemsToShow: 17,
					snapAlign: "start"
				}
			},
        }
    },
    data () {
        return {
            id: parseInt(this.$route.params.id),
            players: {},
            volume: 90,
            HLSCore,
            // bannerimg: 'https://vaohang.com/wp-content/uploads/images/bn.png',
            // liveStrSource: 'https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8',
            items: [],
            listLiveidol: [],
            token: window.localStorage.getItem('token'),
            rank: window.localStorage.getItem('rank'),
            idus: window.localStorage.getItem('numberus'),
            checkHidHeader: window.localStorage.getItem('hiddenhd'),
            var_res: '',
            page: 1,
            perPage: 12,
            totalCount: 0,
            datas: [],
            current_view: '',
            id_live: this.$route.params.idlive,
            nameslug: this.$route.name,
            stickerDV: '',
            messagePin: '',
            showModal: false,
            showModal_dk: false,
            messPin: '',
            other: '',
            listIcon: [],
            fixed_scroll: '',
            showDiv: '',
            editorConfig: {
                toolbar: [ 'link', 'italic', 'bold'],
            },
            currentUrl: window.location.pathname,
            showModal_lg_rg: false,
            tabSelecteds: 'login',
            tell: '',
            checkClick: 0,
            topic: '',
            titlesp: '',
            content: '',
            listtopic: [],
            showModalsp: false,
            showModal_gift: false,
            fullname: '',
            facebook: '',
            telegram: '',
            telegrams: '',
            linkgame: '',
            offline: '',
            pin_message: '',
            country: 'Vietnam',
            classMb: '',
            linkf8bet: '',
            listLive_idol: [],
            codeCapchar: Math.floor(1000 + Math.random() * 9000),
            stickyEnabled: true,
            nameIdol: '',
            checkFolow: 0,
            logoWb: ''
        }
    },
    components: {
        FormChat_nm,
        Select2,
        Carousel,
        Slide,
        Pagination,
        Navigation,
        Login_f8,
        Register_f8,
    },
    mounted() {
        const isMobile = /Mobi/i.test(navigator.userAgent)
        if(isMobile){
            this.classMb = 'iframeMobile'
        }
        let ss = sessionStorage.getItem("session");
        if(this.token === null && ss == null){
            var title_ss = 'user0';
            var num_ran = Math.floor(Math.random() * 1000000000);
            var session = title_ss + num_ran
            sessionStorage.setItem("session", session);
        }
        $.ajax({
            // <div class="dtxt" title="23 Nov 22, 02:10 AM" data-time="${this.created_at}">${timeAgo(this.created_at)}</div>
            url: 'https://adm.liveshow.top/api/chat/list-message',
            type: "GET",
            data: { id_live : this.id_live },
            dataType: 'json',
            success : function( response ) {
                // var url_append = $('input[name="url-chat"]').val();
                var url_append = response.data[0].live_id;
                // console.log('url_append', response.data[0].live_id);
                $.each(response.data, function(){
                    if(this.sticker !== null){
                        this.stickerDV = `<div class="namechat_live">${this.fullname} <b class="point_css point_css_chat point_css${this.level_rank}">${this.level_rank}</b></div><div class="imgsticker"><img src="https://adm.liveshow.top/${this.sticker}" alt=""></div>`;
                    }else{
                        if(this.type==3){
                            this.stickerDV = `<div class="namechat_live namechat_live_inroom">${this.fullname} <b class="point_css point_css_chat point_css${this.level_rank}">${this.level_rank}</b><span class="textchat_content">${this.content}</span></div>`;
                        }else if(this.type==4){
                            this.stickerDV = `<div class="namechat_live namechat_live_inroom">${this.fullname} <b class="point_css point_css_chat point_css${this.level_rank}">${this.level_rank}</b><span class="textchat_content"><a href="${this.content}" target="_blank">${this.content}</a></span></div>`;
                        }else{
                            this.stickerDV = `<div class="namechat_live">${this.fullname} <b class="point_css point_css_chat point_css${this.level_rank}">${this.level_rank}</b><span class="textchat_content">${this.content}</span></div>`;
                        }
                    }
                    let str = `<div class="wp_item_chat_live msg msg-${this.id}">
                                ${this.stickerDV}
                            </div>`;
                    setTimeout(() => {
                        $(`#${url_append}`).prepend(str);
                        var hg = $('.chat-text').prop('scrollHeight');
                        $('.chat-text').animate({ scrollTop: hg+100+'px' }, 5);
                    }, 500);
                    
                });
            },
        });
    },
    created () {
        this.getDetaillive(this.id)
        this.getInfoLink()
        // this.getMessPin()
        this.listIconChat()
        if(this.token !== null){
            this.getTimeView()
            setInterval(this.getTimeView, 180000);
            this.getListtopic()
            this.checkfllow()
        }else{
            this.getTimeViewLive()
            setInterval(this.getTimeViewLive, 180000);
        }
        // window.addEventListener('scroll', this.handleScroll)
        this.getSocketGift()
        this.socketLive()
        this.getInfoById()
        this.getlocation()
        this.addClassHide()
    },
    methods: {
        getInfoLink () {
            this.axios.get(this.api_previewLink + '?domain=' + this.url_param).then((response) => {
                this.messagePin = response.data.data.list_data.pin_message
                this.linkgame = response.data.data.list_data.link_game;
                this.facebook = response.data.data.list_data.facebook
                this.telegram = response.data.data.list_data.telegram
                this.telegrams = response.data.data.list_data.telegram2
                this.linkf8bet = response.data.data.list_data.banner
                this.logoWb = response.data.data.list_data.logo
            })
        },
        getDetaillive (id) {
            this.axios.get(this.api_listlive).then((response) => {
                this.listLive_idol = response.data.data.list_user.filter(d => d.id !== id)
                this.items = response.data.data.list_user.filter(d => d.id === id)

                this.nameIdol = this.items[0].fullname

                this.listLiveidol = response.data.data.list_user.filter(d => d.id !== id)
                this.totalCount = this.listLiveidol.length
                this.paginatedData()


                // const dataImage = [];
                // this.listLiveidol.forEach(item => {
                //     let check_mbut = 0
                //     this.axios.get(item.link_m3u8).then(res => {
                //         if(res.status===200){
                //             check_mbut = 1
                //             dataImage.push({...item, check_mbut});
                //         }
                //     }).catch(e => {
                //         if (e.response.status === 404) {
                //             check_mbut = 0
                //         }else{
                //             check_mbut = 1
                //             dataImage.push({...item, check_mbut});
                //         }
                //     })
                // });
                // setTimeout(() => {
                //     this.listLiveidol = dataImage;
                //     this.totalCount = this.listLiveidol.length
                //     this.paginatedData()
                // }, 500);
            })
        },
        paginatedData () {
            this.datas = this.listLiveidol.slice((this.page - 1) * this.perPage, this.page * this.perPage)
        },
        clickCallback (pageNum) {
            this.page = pageNum;
            this.paginatedData();
        },
        viewCore(id, player) {
            this.players[id] = player;
            this.play(id)
        },
        play(id) {
            this.players && this.players[id] && this.players[id].play();
        },
        playEnded() {
        },
        pip(id) {
            this.players &&
            this.players[id] &&
            this.players[id].requestPictureInPicture();
        },
        format_date(value){
            if (value) {
                return moment(String(value)).format('hh:mm DD/MM')
            }
        },
        // handleScroll () {
        //     if (window.scrollY > 680) {
        //         this.fixed_scroll = 'active_right'
        //         this.showDiv = 'unactive_right'
        //     }else{
        //         this.fixed_scroll = ''
        //         this.showDiv = ''
        //     }
        // },
        getTimeView () {
            this.axios.get(this.api_view + '?user_id=' + this.id, 
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }
            ).then((response) => {
                // console.log(response);
                this.current_view = response.data.data.current_view
                // this.var_res = response
            }).catch(e => {
                // console.log(e);
                this.var_res = e
            })
        },
        getTimeViewLive () {
            let session = sessionStorage.getItem("session");

            this.axios.get(this.api_view_live + '?user_id=' + this.id + '&session=' + session).then((response) => {
                // console.log(response);
                // this.var_res = response
                this.current_view = response.data.data.current_view
            }).catch(e => {
                // console.log(e);
                this.var_res = e
                window.location.href = '/'
            })
        },
        getMessPin () {
            this.axios.get(this.api_messagepin + '?id_live=' + this.id_live
                // {
                //     headers: {
                //         Authorization: 'Bearer ' + this.token
                //     }
                // }
            ).then((response) => {
                this.messagePin = response.data.data.message
            })
        },
        deleteMess (e) {
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            this.axios.post(this.api_updateMess,
                {
                    message: '',
                    id_live: this.id_live
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }
            ).then((response) => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
            })
        },
        updateMess (e) {
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            this.showModal = false
            this.axios.post(this.api_updateMess,
                {
                    message: this.messPin,
                    id_live: this.id_live
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }
            ).then((response) => {
                $('#loading_show').css('display', 'none')
                this.messPin = ''
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
            })
        },
        listIconChat (){
            if(this.token == null){
                this.axios.get(this.api_liststickerlg).then((response) => {
                    this.listIcon = response.data.data.data_sticker
                })
            }else{
                this.axios.get(this.api_liststicker, {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    } 
                }).then((response) => {
                    this.listIcon = response.data.data.data_sticker
                })
            }
        },
        sendIconChat (e) {
            e.preventDefault();
            $('.loading_show').show().fadeIn(10)
            var dataimage = e.target.getAttribute('data_img')
            if(this.token == null){
                let ss = sessionStorage.getItem("session");
                let namess = 'Khách' + ss.substring(10, 14)
                this.axios.post(this.api_sendMessagelg, 
                    {
                        id_live: this.id_live,
                        content: '',
                        sticker: dataimage,
                        name: namess,
                        session_id: ss
                    },
                    {
                        headers: {
                            Authorization: 'Bearer ' + this.token
                        }
                    }
                ).then((response) => {
                    $('#loading_show').css('display', 'none')
                    this.var_res = response
                }).catch(e => {
                    $('#loading_show').css('display', 'none')
                    Swal.fire({
                        position: 'top-end',
                        showConfirmButton: false,
                        title: e.response.data.message,
                        icon: 'error',
                        timer: 2000
                    });
                })
                return false;
            }else{
                this.axios.post(this.api_sendMessage, 
                    {
                        id_live: this.id_live,
                        content: '',
                        sticker: dataimage
                    },
                    {
                        headers: {
                            Authorization: 'Bearer ' + this.token
                        }
                    }
                ).then((response) => {
                    $('#loading_show').css('display', 'none')
                    this.var_res = response
                }).catch(e => {
                    $('#loading_show').css('display', 'none')
                    Swal.fire({
                        position: 'top-end',
                        showConfirmButton: false,
                        title: e.response.data.message,
                        icon: 'error',
                        timer: 2000
                    });
                })
                return false;
            }
        },
        copyToClipBoard (textToCopy) {
            navigator.clipboard.writeText(textToCopy)
            Swal.fire({
                position: 'top-end',
                showConfirmButton: false,
                title: 'Copy thành công',
                icon: 'success',
                timer: 2000
            });
        },
        FormLoginShow () {
            this.showModal = true
            this.tabSelecteds = 'login'
            this.checkClick = 1
        },
        changeTabs (tab) {
            this.tabSelecteds = tab
        },
        sendSupport (e) {
            e.preventDefault();
            $('.loading_show').show().fadeIn(10)
            this.axios.post(this.api_sendSupport, 
                {
                    topic_id: this.topic,
                    title: this.titlesp,
                    content: this.content,
                    support_id: 0
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }
            ).then((response) => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                })
                this.showModalsp = false,
                window.location.href = '/support'
                this.topic_id = this.title = this.content = ''
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: e.response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                })
            })
            return false;
        },
        getListtopic () {
            this.axios.get(this.api_listtopic).then((response) => {
                let listtopic = response.data.data
                let datatp = []
                listtopic.forEach(item => {
                    datatp.push({...item, text: item.title});
                });
                this.listtopic = datatp
            })
        },
        getSocketGift () {
            var thispage = this
            var socket = SocketIO(this.link_web,{ transports: ['websocket', 'polling', 'flashsocket'] });
            socket.on('gift:send',function(result){
                // console.log('test socket', result.data.gift, thispage.id);
                if (result.data.gift.idol_id == thispage.id) {
                    thispage.showModal_gift = true
                }
                window.localStorage.setItem('gf', result.data.gift.id)
            });
        },
        sendInfoGift (e) {
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            var idgd = window.localStorage.getItem('gf')
            if (this.token == null) {
                this.axios.post(this.api_takeGift,
                    {
                        name: this.fullname,
                        phone: this.phone,
                        gift_id: idgd,
                    }
                ).then(res => {
                    $('#loading_show').css('display', 'none')
                    this.$swal({
                        position: 'top-end',
                        icon: 'success',
                        title: res.data.message,
                        showConfirmButton: false,
                        timer: 4000
                    })
                    this.showModal_gift = false
                    this.fullname = this.phone = ''
                }).catch(e => {
                    $('#loading_show').css('display', 'none')
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                })
            }else{
                this.axios.post(this.api_takeGiftLogin, 
                    {
                        phone: this.phone,
                        gift_id: idgd,
                    },
                    {
                        headers: {
                            Authorization: 'Bearer ' + window.localStorage.getItem('token')
                        }
                    }
                ).then(res => {
                    $('#loading_show').css('display', 'none')
                    this.$swal({
                        position: 'top-end',
                        icon: 'success',
                        title: res.data.message,
                        showConfirmButton: false,
                        timer: 4000
                    })
                    this.showModal_gift = false
                    this.phone = ''
                }).catch(e => {
                    $('#loading_show').css('display', 'none')
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                })
            }
        },
        socketLive() {
            var thisAgo = this
            var socket = SocketIO('https://adm.liveshow.top/', {
                transports: ['websocket', 'polling', 'flashsocket'],
            });
            socket.on('livestream:realtime',function(result){
                if(result.data.user.live==1){
                    if(result.data.user.type_live==1){
                        thisAgo.listLiveidol = thisAgo.listLiveidol.concat(result.data.user)
                        thisAgo.totalCount = thisAgo.listLiveidol.length
                        thisAgo.paginatedData()
                    }
                }else{
                    thisAgo.listLiveidol.splice(thisAgo.listLiveidol.findIndex(({id}) => id == result.data.user.id), 1);
                    thisAgo.totalCount = thisAgo.listLiveidol.length
                    thisAgo.paginatedData()
                }
            });
        },
        getInfoById () {
            this.axios.get(this.api_infidol + '?id=' + this.id).then((response) => {
                this.offline = response.data.data.user.offline
                this.pin_message = response.data.data.user.pin_message
            }).catch(e => {
                if (e.response.status === 401) {
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 11000
                    })
                    setTimeout(function() {
                        this.axios.post(this.api_logout, {
                        }).then(res => {
                            if (res.data.success === true) {
                                localStorage.clear();
                            }
                        })
                    }.bind(this), 5000);
                    setTimeout(function() {
                        window.location.href = '/'
                    }, 13000);
                }
            })
        },
        getlocation () {
            this.axios.get('https://geolocation-db.com/json/').then((response) => {
                this.country = response.data.country_name
            })
        },
        pauseVideo () {
            // var theProtocol = location.protocol
            // var theURL = theProtocol + "//f8game.store"
            // var iframeTag = document.querySelector("iframe")
            // var win = iframeTag.contentWindow;
            // win.postMessage("pauseVideo", theURL);

            // $('.iframe_videof8_dt').contents().find('video').each(function () {
            //     this.pause();
            // });

            // $('.iframe_videof8_dt').contents().find('video')[0].pause();
        },
        viewmodal_rg () {
            this.showModal_dk = true
            this.showModal = false
        },
        viewmodal_lg () {
            this.showModal = true
            this.showModal_dk = false
        },
        addClassHide () {
            $('.checkHidHeader').parent().addClass('hideheader')
        },
        showModalrg () {
            this.showModal = false
            this.showModal_dk = true
        },
        showModallg () {
            this.showModal = true
            this.showModal_dk = false
        },
        followIdol (e) {
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            this.axios.post(this.api_follow, 
                {
                    id: this.id
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + window.localStorage.getItem('token')
                    }
                }
            ).then(res => {
                $('#loading_show').css('display', 'none')
                this.checkFolow = 1
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                this.checkFolow = 0
                if(e.response.status === 404){
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                }
            })
        },
        unfollowIdol (e) {
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            this.axios.post(this.api_unfollow, 
                {
                    id: this.id
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + window.localStorage.getItem('token')
                    }
                }
            ).then(res => {
                $('#loading_show').css('display', 'none')
                this.checkFolow = 0
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                this.checkFolow = 1
                if(e.response.status === 404){
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                }
            })
        },
        checkfllow () {
            this.axios.get(this.api_listfollow,
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }
            ).then((response) => {
                if(response.data.data.filter(d => d.id == this.id).length > 0){
                    this.checkFolow = 1
                }else{
                    this.checkFolow = 0
                }
            })
        },
    },
    beforeUnmount () {
        window.localStorage.setItem('hiddenhd', 0)
        $('#app').removeClass('hideheader')
    }
}
</script>
<style>
</style>
