<template>
    <div class="root_info_user">
        <div class="container ner_support ner_faq">
            <div class="list_chat_support list_chat_support_faq" style="background: transparent;">
                <div class="at-item" v-for="(item, index) in datas" :key="index">
                    <div class="at-title">
                        <h2>{{ item.title }}</h2>
                    </div>
                    <div class="at-tab" v-html="item.content"></div>
                </div>
                <paginate @update:modelValue="clickCallback" :totalCount="totalCount" :limit="perPage" v-model="page"></paginate>
            </div>
            <p class="link_spp" v-if="token != null">
                <router-link to="/support" title="Liên hệ với nhân viên">
                    <img src="../assets/images/chat.png" alt=""><br />
                    <span>Chát với nhân viên</span>
                </router-link>
            </p>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
$( document ).ready(function() {
    $(document).on('click', '.at-title', function() {
        $(this).toggleClass("active").next(".at-tab").slideToggle().parent().siblings().find(".at-tab").slideUp().prev().removeClass("active");
    });
});
export default {
    name: 'Faq_nm',
    data () {
        return {
            token: window.localStorage.getItem('token'),
            listfaq: [],
            page: 1,
            perPage: 20,
            totalCount: 0,
            datas: [],
        }
    },
    components: {
    },
    created () {
        this.getListfaq()
    },
    methods: {
        getListfaq () {
            this.axios.get(this.api_listFaq).then((response) => {
                this.listfaq = response.data.data.list_data
                this.totalCount = this.listfaq.length
                this.paginatedData()
            })
        },
        paginatedData () {
            this.datas = this.listfaq.slice((this.page - 1) * this.perPage, this.page * this.perPage)
        },
        clickCallback (pageNum) {
            this.page = pageNum;
            this.paginatedData();
        },
    }
}
</script>
<style>
</style>
