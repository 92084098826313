<template>
    <div class="wrapper_list_idol">
        <div class="container ner_responesize">
            <div class="wp_list_idol">
                <div class="wraper_game_nohu">
                    <div class="row row_5_mb">
                        <div class="col-md-6 col-sm-6 col-xs-6 item_list_idol wp-item-live" v-for="(item, indexs) in loadMore_bxh" :key="indexs">
                            <div class="item_live_idol">
                                <router-link v-bind:to="{ name: 'InfoIdol_nm', params: { id: item.id } }">
                                    <div class="img_live img_lives" v-if="item.sex == 0">
                                        <img :src="link_web + item.hinh_anh" alt="" v-if="item.hinh_anh != null">
                                        <img src="../assets/images/lv.png" alt="" v-else>
                                    </div>
                                    <div class="img_live img_lives" v-else>
                                        <img :src="link_web + item.hinh_anh" alt="" v-if="item.hinh_anh != null">
                                        <img src="../assets/images/oto.png" alt="" v-else>
                                    </div>
                                    <div class="text_item_live">
                                        <div class="img_live_small">
                                            <img :src="link_web + item.hinh_anh" alt=""  v-if="item.hinh_anh != null">
                                            <img src="../assets/images/user.png" alt="" v-else>
                                        </div>
                                        <div class="text_live_small">
                                            <h3 style="text-align: left;" class="font1">{{ item.name }}</h3>
                                            <div class="type_live">
                                                <p>
                                                    <img src="../assets/images/eye.svg" alt="">
                                                    <span class="sp-02">{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(item.point) }}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <p class="detail_mobile_live" v-if="length_hot < listBxh.length"><a @click="loadMore">Xem thêm</a></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ListIdol_nm',
    data () {
        return {
            listBxh: [],
            length_hot: 15,
        }
    },
    created () {
        this.getListBxh()
    },
    computed: {
        loadMore_bxh() {
            return this.listBxh.slice(0, this.length_hot);
        },
    },
    methods: {
        getListBxh () {
            this.axios.get(this.api_listBxh).then((response) => {
                this.listBxh = response.data.data.list_user
            })
        },
        loadMore() {
            if (this.length_hot > this.listBxh.length) return;
            this.length_hot = this.length_hot + 5;
        },
    }
}
</script>