// import Vue from "vue";
import { createStore } from "vuex";
import axios from 'axios'


const store = createStore({
    state:{
        user: []
    },
    getters:{
        
    },
    mutations:{
        InfoUser(state) {
            axios.get(`https://f8game.info/api/info-user`, {
                headers: {
                    Authorization: 'Bearer ' + window.localStorage.getItem('token')
                }
            }).then((res) => {
                state.user = res.data.data.user
            }).catch(e => {
                if (e.response.status === 401) {
                    setTimeout(function() {
                        this.axios.post('https://f8game.info/api/logout', {
                        }).then(res => {
                            if (res.data.success === true) {
                                localStorage.clear();
                            }
                        })
                    }.bind(this), 5000);
                    setTimeout(function() {
                        window.location.href = '/'
                    }, 13000);
                }
            })
        },
    },
    actions:{
        InfoUser(context) {
            context.commit('InfoUser');
        },
    }
});

export default store;
