<template>
    <div class="root_info_user">
        <div class="info_wrapper">
            <div class="wrapper_intro" style="margin-bottom: 30px;">
                <div class="previewidol_live">
                    <div class="cat_chanel">
                        <ul>
                            <li><img :src="link_web + logoWb" alt=""></li>
                            <li :class="{active: tabSelected === 'notification'}" v-on:click="changeTab('notification')">Thông báo</li>
                        </ul>
                    </div>
                    <div class="content_tab_chanel">
                        <div v-if="tabSelected === 'notification'">
                            <div class="scrollNotiPage">
                                <ul>
                                    <li :class="{notview: item.view_web==1}" v-for="(item, index) in datas" :key="index">
                                        <p v-if="item['arrinfo'][0].action == 'register_approve'">Tài khoản <b>{{ item['arrinfo'][0].fullname }}</b> đã được duyệt làm idol. Tên đăng nhập <b>{{ item['arrinfo'][0].username }}</b>, mật khẩu <b>{{ item['arrinfo'][0].password }}</b> <span>{{ formatDate(item.created_date) }}</span></p>
                                        <p v-else-if="item['arrinfo'][0].action == 'register_cancel'">Tài khoản <b>{{ item['arrinfo'][0].fullname }}</b> không được duyệt làm idol. <span>{{ formatDate(item.created_date) }}</span></p>
                                        <p v-else-if="item['arrinfo'][0].action == 'qc_create'">Tài khoản <b>{{ item['arrinfo'][0].fullname }}</b> đã bị phạt lần thứ <b>{{ item['arrinfo'][0].level }}</b> mức phạt <b>{{ item['arrinfo'][0].value }}%</b>. <span>{{ formatDate(item.created_date) }}</span></p>
                                        <p v-else-if="item['arrinfo'][0].action == 'qc_delete'">Tài khoản <b>{{ item['arrinfo'][0].fullname }}</b> đã được xóa phạt. <span>{{ formatDate(item.created_date) }}</span></p>
                                        <p v-else>Tài khoản <b>{{ item['arrinfo'][0].fullname }}</b> đã được cấp phát luồng livestream. <span>{{ formatDate(item.created_date) }}</span></p>
                                    </li>
                                </ul>
                            </div>
                            <paginate @update:modelValue="clickCallback" :totalCount="totalCount" :limit="perPage" v-model="page"></paginate>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import $ from 'jquery'
export default {
    name: 'Notification_nm',
    data () {
        return {
            tabSelected: 'notification',
            page: 1,
            perPage: 20,
            totalCount: 0,
            datas: [],
            token: window.localStorage.getItem('token'),
            listNotification: [],
            logoWb: ''
        }
    },
    components: {
    },
    created () {
        this.GetNoti()
        this.getInfoLink()
    },
    methods: {
        getInfoLink () {
            this.axios.get(this.api_previewLink + '?domain=' + this.url_param).then((response) => {
                this.logoWb = response.data.data.list_data.logo
            })
        },
        changeTab (tab) {
            this.tabSelected = tab
        },
        formatDate (date) {
            var splitdate = date.split(' ')
            var datePart = splitdate[0].match(/\d+/g),
            year = datePart[2].substring(0),
            month = datePart[1], 
            day = datePart[0];
            // var gpg = splitdate[1].split(':');
            // gpg[0] + ':' + gpg[1] + ' ' + 
            return year + '-' + month + '-' + day;
        },
        GetNoti () {
            this.axios.get(this.api_listNoti,
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }
            ).then((response) => {
                let listNoti = response.data.data.notifications
                this.numberNoti = response.data.data.num_notifi
                const arr = [];
                listNoti.forEach(item => {
                    let arrinfo = JSON.parse(item.content)
                    arr.push({...item, arrinfo});
                });
                setTimeout(() => {
                    this.listNotification = arr;
                    this.totalCount = this.listNotification.length
                    this.paginatedData()
                }, 800);
            })
        },
        paginatedData () {
            this.datas = this.listNotification.slice((this.page - 1) * this.perPage, this.page * this.perPage)
        },
        clickCallback (pageNum) {
            this.page = pageNum;
            this.paginatedData();
        },
    }
}
</script>
<style>
</style>
