<template>
    <div class="root_info_user">
        <div class="container ner_support" v-if="token!=null">
            <div class="type_group_chanel">
                <div class="cancelgroup" v-if="ididol != idUsLC && checkMember == 1">
                    <p class="exitgroupMes" @click="exitGroupMes">Thoát group</p>
                </div>
                <p style="display:none;" v-else-if="checkMember == 2"></p>
            </div>
            <div class="title_chanel_detail">
                <p>{{ titleChanel }}</p>
            </div>
            <div class="list_chat_support list_chat_support_detail">
                <input type="hidden" class="id_support_input" :value="id">
                <router-link v-if="token != null" v-bind:to="{ name: 'Info_nm', params: { id: idUsLC } }" class="comeback_support"><img src="../assets/images/cb.png" alt=""></router-link>
                <div class="scroll_div_support" v-if="ididol == idUsLC">
                    <div :class="'item_chat_detail item'+item.id" v-for="(item, index) in listMes" :key="index">
                        <!-- user -->
                        <div class="wp_item_chatdt_right" v-if="item.user_id == idUsLC">
                            <div class="content_chatdt">
                                <p class="ngaytao_chat ngaytao_chat1"><span>{{ item.fullname }}</span></p>
                                <div class="content_child" v-if="item.status != 0">
                                    <p v-if="item.type_mes == 1">{{ item.content }} <span>{{ item.ngay_tao }}</span></p>
                                    <p v-else-if="item.type_mes == 2"><a :href="link_web + item.url_image" target="_blank"><img :src="link_web + item.url_image" alt=""></a><span>{{ item.ngay_tao }}</span></p>
                                    <p v-else><a :href="item.content" target="_blank">{{ item.content }}</a> <span>{{ item.ngay_tao }}</span></p>
                                    <div class="delete_mesIdol" style="margin-right: 5px;" v-if="item.status != 0">
                                        <img src="../assets/images/delete.png" alt="" :data_id="item.id" @click="deleteMesDetail($event)">
                                    </div>
                                </div>
                                <div class="content_child" v-else>
                                    <p class="delete_mes_p">Tin nhắn đã bị xóa</p>
                                </div>
                            </div>
                            <div class="img_chat_detail">
                                <img :src="link_web + item.hinh_anh" alt="">
                            </div>
                        </div>
                        <!-- admin -->
                        <div class="wp_item_chatdt_left" v-else>
                            <div class="img_chat_detail">
                                <img :src="link_web + item.hinh_anh" alt="">
                            </div>
                            <div class="content_chatdt">
                                <div class="content_child">
                                    <p class="ngaytao_chat ngaytao_chat1"><span>{{ item.fullname }}</span></p>
                                    <div class="left_content_mes" v-if="item.status != 0">
                                        <p v-if="item.type_mes == 1">{{ item.content }} <span>{{ item.ngay_tao }}</span></p>
                                        <p v-else-if="item.type_mes == 2"><a :href="link_web + item.url_image" target="_blank"><img :src="link_web + item.url_image" alt=""></a> <span>{{ item.ngay_tao }}</span></p>
                                    </div>
                                    <div class="left_content_mes" v-else>
                                        <p class="delete_mes_p">Tin nhắn đã bị xóa</p>
                                    </div>
                                    <div class="delete_mesIdol" v-if="item.status != 0">
                                        <img src="../assets/images/delete.png" alt="" :data_id="item.id" @click="deleteMesDetail($event)">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="scroll_div_support" v-else-if="checkMember == 1">
                    <div :class="'item_chat_detail item'+item.id" v-for="(item, index) in listMes" :key="index">
                        <div class="wp_item_chatdt_right" v-if="item.user_id == idUsLC">
                            <div class="content_chatdt">
                                <p class="ngaytao_chat ngaytao_chat1"><span>{{ item.fullname }}</span></p>
                                <div class="content_child" v-if="item.status != 0">
                                    <p v-if="item.type_mes == 1">{{ item.content }} <span>{{ item.ngay_tao }}</span></p>
                                    <p v-else-if="item.type_mes == 2"><a :href="link_web + item.url_image" target="_blank"><img :src="link_web + item.url_image" alt=""></a> <span>{{ item.ngay_tao }}</span></p>
                                    <p v-else><a :href="item.content" target="_blank">{{ item.content }}</a> <span>{{ item.ngay_tao }}</span></p>
                                </div>
                                <div class="content_child" v-else>
                                    <p class="delete_mes_p">Tin nhắn đã bị xóa</p>
                                </div>
                            </div>
                            <div class="img_chat_detail">
                                <img :src="link_web + item.hinh_anh" alt="">
                            </div>
                        </div>
                        <div class="wp_item_chatdt_left" v-else>
                            <div class="img_chat_detail">
                                <img :src="link_web + item.hinh_anh" alt="">
                            </div>
                            <div class="content_chatdt">
                                <div class="content_child">
                                    <p class="ngaytao_chat ngaytao_chat1"><span>{{ item.fullname }}</span></p>
                                    <div class="left_content_mes" v-if="item.status != 0">
                                        <p v-if="item.type_mes == 1">{{ item.content }} <span>{{ item.ngay_tao }}</span></p>
                                        <p v-else-if="item.type_mes == 2">
                                            <a :href="link_web + item.url_image" target="_blank"><img :src="link_web + item.url_image" alt=""></a> 
                                            <span>{{ item.ngay_tao }}</span>
                                        </p>
                                    </div>
                                    <div class="left_content_mes" v-else>
                                        <p class="delete_mes_p">Tin nhắn đã bị xóa</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="add_member_group" v-else-if="checkMember == 2">
                    <p class="addGroupMes"><span @click="joinGroup">+ Tham gia</span></p>
                </div>
                <div class="block_member_group" v-else>
                    <p class="blockMember">Bạn đã bị chặn</p>
                </div>
                <div class="form_chat_support" v-if="ididol == idUsLC || idcheck == 1 && checkMember == 1">
                    <form action="" method="post" id="frm_chatsupport" @submit="sendChatGroup">
                        <input type="text" class="content_rep" v-model="content" placeholder="Nội dung">
                        <div class="input_file_img">
                            <input type="file" name="file_img_sp" id="file_img_sp" @change="submitImg($event)"/>
                            <label class="file_img_sp" for="file_img_sp">
                                <font-awesome-icon icon="fa-solid fa-image" />
                            </label>
                        </div>
                        <button type="submit">Gửi</button>
                    </form>
                </div>
            </div>
        </div>
        <!-- modal login -->
        <div id="modal-example" class="modal-login">
            <div class="overlay" v-if="showModal" @click="showModal = false"></div>
            <div class="modal" v-if="showModal">
                <div class="wp-modal-login">
                    <div class="header-modal">
                        <button class="close-modal_login" @click="showModal = false"><img src="../assets/images/close_md.png" alt=""></button>
                    </div>
                    <div class="content-modal_tab">
                        <div>
                            <div class="logo_modal_login">
                                <div class="logo_mdal">
                                    <div class="img_md">
                                        <img :src="link_web + logoWb" alt="">
                                    </div>
                                </div>
                            </div>
                            <Login_f8 @showMd="showModalrg" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end modal login -->
        <!-- modal register -->
        <div id="modal-example" class="modal-login">
            <div class="overlay" v-if="showModal_dk" @click="showModal_dk = false"></div>
            <div class="modal" v-if="showModal_dk">
                <div class="wp-modal-register">
                    <div class="header-modal">
                        <button class="close-modal_login" @click="showModal_dk = false"><img src="../assets/images/close_md.png" alt=""></button>
                    </div>
                    <div class="content-modal_tab">
                        <div>
                            <div class="logo_modal_login">
                                <div class="logo_mdal">
                                    <div class="img_md">
                                        <img :src="link_web + logoWb" alt="">
                                    </div>
                                </div>
                            </div>
                            <div class="form_dangky">
                                <Register_f8 @showMd="showModallg"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end modal register -->
    </div>
</template>

<script>
import Login_f8 from '@/components/Login'
import Register_f8 from '@/components/Register'
import $ from 'jquery'
import SocketIO from 'socket.io-client'
export default {
    name: 'MessageGroup_nm',
    data () {
        return {
            ididol: parseInt(this.$route.params.ididol),
            idcheck: parseInt(this.$route.params.idcheck),
            idMes: this.$route.params.id,
            token: window.localStorage.getItem('token'),
            idUsLC: window.localStorage.getItem('numberus'),
            imgUS: window.localStorage.getItem('img'),
            listMes: [],
            fullname: '',
            hinh_anh: '',
            content: '',
            other: '',
            showModal: false,
            showModal_dk: false,
            tabSelecteds: 'login',
            email_forgot: '',
            checkMember: 1,
            titleChanel: '',
            linkf8bet: '',
            logoWb: ''
        }
    },
    components: {
        Login_f8,
        Register_f8,
    },
    created () {
        if (this.token != null) {
            setTimeout(function() {
                if(this.checkMember==1){
                    this.getListMes()
                }
            }.bind(this), 1000);
            this.getCheckMember()
            this.getInfoIdol()
        }else{
            this.showModal = true
        }
        this.connect()
        this.getInfoLink()
    },
    methods: {
        getInfoLink () {
            this.axios.get(this.api_previewLink + '?domain=' + this.url_param).then((response) => {
                this.linkf8bet = response.data.data.list_data.banner
                this.logoWb = response.data.data.list_data.logo
            })
        },
        getInfoIdol () {
            this.axios.get(this.api_infidol + '?id=' + this.ididol).then((response) => {
                this.hinh_anh = response.data.data.user.hinh_anh
                this.fullname = response.data.data.user.fullname
            }).catch(e => {
                if (e.response.status === 401) {
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 11000
                    })
                    setTimeout(function() {
                        this.axios.post(this.api_logout, {
                        }).then(res => {
                            if (res.data.success === true) {
                                localStorage.clear();
                            }
                        })
                    }.bind(this), 5000);
                    setTimeout(function() {
                        window.location.href = '/'
                    }, 13000);
                }
            })
        },
        getListMes () {
            this.axios.get(this.api_listMesGroup + '?id=' + this.idMes, {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
            }).then((response) => {
                this.listMes = response.data.data.reverse()
                this.titleChanel = response.data.title
                setTimeout(() => {
                    var hg = $('.scroll_div_support').prop('scrollHeight');
                    $('.scroll_div_support').animate({ scrollTop: hg+100 }, 5);
                }, 500);
            })
        },
        sendChatGroup (e) {
            e.preventDefault();
            $('.loading_show').show().fadeIn(10)
            let url_image = document.getElementById('file_img_sp').files[0]
            const form = new FormData();
            form.append('id', this.idMes);
            form.append('url_image', url_image);
            form.append('content', this.content);
            this.axios.post(this.api_chatIdolGroup, 
                form,
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }
            ).then((response) => {
                $('#loading_show').css('display', 'none')
                this.other  = response
                this.content = ''
                $("#file_img_sp").val('');
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: e.response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                })
            })
            return false;
        },
        submitImg (event) {
            event.preventDefault();
            $('.loading_show').show().fadeIn(10)
            let url_image = document.getElementById('file_img_sp').files[0]
            const form = new FormData();
            form.append('id', this.idMes);
            form.append('url_image', url_image);
            form.append('content', this.content);
            this.axios.post(this.api_chatIdolGroup, 
                form,
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }
            ).then((response) => {
                $('#loading_show').css('display', 'none')
                this.other  = response
                $("#file_img_sp").val('');
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: e.response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                })
            })
            return false;
        },
        formatDate (date) {
            var splitdate = date.split(' ')
            var datePart = splitdate[0].match(/\d+/g),
            year = datePart[2].substring(0),
            month = datePart[1], 
            day = datePart[0];
            var gpg = splitdate[1].split(':');
            return  gpg[0] + ':' + gpg[1] + ' ' + year + '-' + month + '-' + day;
        },
        connect() {
            var thisAgo = this
            var socket = SocketIO('f8game.click', {
                transports: ['websocket', 'polling', 'flashsocket'],
            });
            socket.on('chat:idol',function(result){
                // console.log(result);
                if( result.data.idol_group === thisAgo.idMes && result.data.action == 7){
                    // console.log(thisAgo.listMes, result.data);
                    const datatest = {
                        "id": result.data.id_mess,
                        "type_mes": result.data.type_mes,
                        "content": result.data.content,
                        "url_image": result.data.url_image,
                        "fullname": result.data.fullname,
                        "hinh_anh": result.data.hinh_anh,
                        "type": result.data.type,
                        "ngay_tao": result.data.ngay_tao,
                        "user_id": result.data.user_id,
                        "status": result.data.status
                    }
                    thisAgo.listMes.push(datatest)
                    var hg = $('.scroll_div_support').prop('scrollHeight');
                    $('.scroll_div_support').animate({ scrollTop: hg+100 },500);
                }else if(result.data.action == 8){
                    thisAgo.listMes.filter(item => item.id === result.data.id_mess).map(function (obj) {
                        obj.status = 0;
                        return obj;
                    });
                    // $(`.item${result.data.id_mess}`).remove();
                }
            });
        },
        timeAgo(str_date) {
            var d = new Date();
            var UTCsecondsNow = (d.getTime() + d.getTimezoneOffset() * 60 * 1000);

            var date = new Date(str_date);
            var UTCseconds = (date.getTime() + date.getTimezoneOffset() * 60 * 1000);
            var diff = UTCsecondsNow - UTCseconds;
            var tense = 'trước';
            if (diff < 0) {
                tense = 'sau';
                diff = Math.abs(diff);
            }
            if (diff === 0) return 0;
            var years = this.singular(Math.round(diff / 31557600000), 'năm');
            if (years)
            return years + tense;
            var months = this.singular(Math.round(diff / 2592000000), 'tháng');
            if (months)
            return months + tense;
            var days = this.singular(Math.round(diff / 86400000), 'ngày');
            if (days)
            return days + tense;
            var hours = this.singular(Math.round(diff / 3600000), 'giờ');
            if (hours)
            return hours + tense;
            var mins = this.singular(Math.round(diff / 60000), 'phút');
            if (mins)
            return mins + tense;
            var secs = this.singular(Math.round(diff / 1000), 'giây');
            if (secs){
                return secs + tense;
            }
            else return 'Vừa xong';
        },
        singular(num, str) {
            if (num >= 1) {
                return num + ' ' + str + ' ';
            }
            return '';
        },
        deleteMesDetail (event) {
            event.preventDefault()
            $('.loading_show').show().fadeIn(10)
            var userID = event.target.getAttribute('data_id')
            this.axios.post(this.api_deleteChatGroup, 
                {
                    id: this.idMes,
                    id_mess: userID
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + window.localStorage.getItem('token')
                    }
                }
            ).then(res => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
            })
        },
        deleteMesDetails (id) {
            console.log(id);
            $('.loading_show').show().fadeIn(10)
            this.axios.post(this.api_deleteChatGroup, 
                {
                    id: this.idMes,
                    id_mess: id
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + window.localStorage.getItem('token')
                    }
                }
            ).then(res => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
            })
        },
        changeTabs (tab) {
            this.tabSelecteds = tab
        },
        forgotpassword (e) {
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            this.showModal = false
            this.axios.post(this.api_forgot, {
                email: this.email_forgot,
            }).then(res => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                if(e.response.status === 404){
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                }
            })
        },
        getCheckMember () {
            this.axios.get(this.api_checkMemberGroup + '?id=' + this.idMes,
                {
                    headers: {
                        Authorization: 'Bearer ' + window.localStorage.getItem('token')
                    }
                }
            ).then((response) => {
                let checkMember = response.data.data
                if(checkMember==1){
                    this.checkMember = 1
                }else if(checkMember==2){
                    this.checkMember = 2
                }else{
                    this.checkMember = 3
                }
            })
        },
        joinGroup () {
            $('.loading_show').show().fadeIn(10)
            this.axios.post(this.api_joinGroup, 
                {
                    id: this.idMes,
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + window.localStorage.getItem('token')
                    }
                }
            ).then(res => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
                this.checkMember = 1
                this.getListMes()
            })
        },
        exitGroupMes () {
            $('.loading_show').show().fadeIn(10)
            this.axios.post(this.api_exitGroupt, 
                {
                    id: this.idMes,
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + window.localStorage.getItem('token')
                    }
                }
            ).then(res => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
                this.checkMember = 2
            })
        },
        viewmodal_rg () {
            this.showModal_dk = true
            this.showModal = false
        },
        viewmodal_lg () {
            this.showModal = true
            this.showModal_dk = false
        },
        showModalrg () {
            this.showModal = false
            this.showModal_dk = true
        },
        showModallg () {
            this.showModal = true
            this.showModal_dk = false
        }
    }
}
</script>
<style>
</style>
